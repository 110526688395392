<template lang='pug'>
	.main-page.page-noScroll.pb-0.position-relative
		v-row#page-head.d-flex.align-center.justify-md-space-between
			v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='6' )
				h1.my-0 {{$t('CAMPAIGN.TITLE')}}
				v-btn.ml-3.edit-deal-btn(@click='showAddCampaign()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
					v-icon(size='16') icon-add
			v-col(cols='12' md='6' )
				v-row.justify-md-end(no-gutters='no-gutters')
					v-col(cols='auto')
						.btn-group
							v-btn(@click='onCopy(selectedRows[0])' :disabled='selectedRows.length > 1 || selectedRows.length == 0' width='80' height='38' color='white' depressed tile dark)
								v-icon(size='16') icon-edit
								span {{$t('GENERAL.COPY')}}
							v-btn.border-left(@click='onBulkDelete()' :disabled="selectedRows.length == 0" width='80' height='38' color='white' depressed tile dark)
								v-icon(size='16') icon-remove
								span {{$t('GENERAL.DELETE')}}
					v-col(cols='auto').ml-4
						.btn-group
							v-text-field(v-model='search' flat dense solo prepend-inner-icon='icon-search')
		#page-inner.scroll-x.pt-md-6.pb-0.height_100vh
			.data-area
				v-data-table.h-100.deals-table.ellipsis(:headers='campaignsHeader' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='campaignList' :search='search' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort height='100%' hide-default-footer='hide-default-footer' show-select item-key='id' v-model="selectedRows" :loading='isLoading' loading-text="Loading..." )
					template(v-slot:item.name='{ item }')
						v-btn.w-100.d-block( :href='"Campaign/" + item.id' color='primary' text :title="item.name") 
							.ellipsis.pt-2.w-100  
								span.w-100  {{ item.name }}
					template(v-slot:item.channelName='{ item }')
						.ellipsis
							span {{ item.channelName }}
					template(v-slot:item.segmentName='{ item }')
						v-btn.w-100.d-block( :href='"Segment/" + item.segment.id' color='primary' text :title="item.segmentName") 
							.ellipsis.pt-2.w-100  
								span.w-100  {{ item.segmentName }}
					template(v-slot:item.people='{ item }')
						.ellipsis
							span(v-if='$i18n.locale == "zh_TW"') {{ item.people + " 人" }}
							span(v-else) {{ item.people }}
					template(v-slot:item.action='{ item }')
						v-btn(icon='icon' @click='showEditCampaign(item)')
							v-icon(size='18') icon-edit
						v-btn(icon='icon' @click='onDelete(item.id)')
							v-icon(size='18') icon-remove
					template(v-slot:item.ownerName='{ item }')
						.d-flex.justify-start.align-center
							v-list-item-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
								v-icon(v-if="item.owner == null") mdi-account
								v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
								img(v-else :src='item.owner.usersetting.photo')
							v-btn.w-75.d-inline-block(v-if="item.owner != null" :href='onMail(item.owner.email)' color='primary' text :title="item.ownerName") 
								.ellipsis.w-100.pt-2  
									span.w-100  {{ item.ownerName }}
				label.text-right.mr-4 {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
				v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
		v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
			message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
		v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
			success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
		v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
			error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
		v-dialog(v-model='addCampaignDialog' max-width='500' @keydown.esc='onEmitAddCampaignDialog(false)' v-if='addCampaignDialog')
			add-campaign(@emitAddCampaignDialog='onEmitAddCampaignDialog' @emitAddCampaignData='onEmitAddCampaignData' :add-campaign='addCampaignData' :segment-list='segmentList')
		v-dialog(v-model='editCampaignDialog' max-width='500' @keydown.esc='onEmitEditCampaignDialog(false)' v-if='editCampaignDialog')
			edit-campaign(@emitEditCampaignDialog='onEmitEditCampaignDialog' @emitEditCampaignData='onEmitEditCampaignData' :edit-campaign='editCampaignData' :has_ai_setting='has_ai_setting' :has_cam_line='has_cam_line')
</template>

<script>
import loadingOverlay from "@/components/Common/loadingOverlay";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import addCampaign from "@/components/Dialog/Campaign/addCampaign";
import editCampaign from "@/components/Dialog/Campaign/editCampaign";
import CampaignDataService from "../services/CampaignDataService";
import SegmentDataService from "@/services/SegmentDataService";
import campaign_channelList from "@/array/the_campaignChannelList";
import campaignStatusList from "@/array/the_campaignStatusList";
import i18n from '/common/plugins/vue-i18n.js'

export default {
  components: {
    loadingOverlay,
    messageDialog,
    successDialog,
    errorDialog,
    addCampaign,
    editCampaign,
  },
  data() {
    return {
      // table setting
      awaitingSearch: false, 
      sortBy:[],
      sortByForSearch:[],
      sortByDesc:[],
      pageDataCount:null,
      page: 1,
      pageCount: 0,
      currentData:0,
      itemPerPage: this.getItemPerPage(),
      selectedRows: [],
      isLoading: true,
      campaignsHeader: [
        {
          value: "data-table-select",
          align: "center",
          width: '100px',
        },
        {
          text: i18n.t('CAMPAIGN.NAME'),
          value: "name",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('CAMPAIGN.CHANNEL'),
          value: "channelName",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('CAMPAIGN_DETAIL.STATUS'),
          value: "statusName",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('CAMPAIGN.SEGMENT'),
          value: "segmentName",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('CAMPAIGN.PEOPLE'),
          value: "people",
          align: "right",
          width: '200px',
          filterable: false,
        },
        {
          text: i18n.t('CAMPAIGN.OWNER'),
          value: "ownerName",
          align: "center",
          width: '200px',
        },
        {
          text: i18n.t('CAMPAIGN.ACTIVE'),
          value: "action",
          align: "center",
          width: '200px',
          sortable: false,
        },
      ],
      // Dialog
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      addCampaignDialog: false,
      editCampaignDialog: false,
      // Data
      campaign_channelList: campaign_channelList,
      campaignStatusList:campaignStatusList,
      originData: {},
      campaignList: [],
      segmentList: [],
      search: "",
      addCampaignData: {},
      editCampaignData: {},
      delId: "",
      delIds: [],
      delIndex: 0,
      delMode: "",
      has_cam_line:false,
      has_ai_setting:false
    };
  },
  async created() {
    await this.getListData()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async getListData(){
      this.campaignList = []
      await (this.isLoading = true);
      await CampaignDataService.list(this.page,this.search,this.itemPerPage,this.sortByForSearch)
      .then(response => {
        this.campaignList = response.data.list;
        this.pageCount = response.data.total_page
        this.pageDataCount = response.data.pageDataCount
        this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage
        this.campaignList.forEach(item => {
          item.segmentName = item.segment.name;
          item.channelName = this.replaceChannel(item.channel);
          item.statusName = this.replaceStatus(item.status);
          item.ownerName = item.owner.last_name + " " + item.owner.first_name;
        });
      });
      await window.addEventListener('resize', this.onResize)
      this.onResize();
    // this.sortByForSearch = []
    await (this.isLoading = false);
    },
    getItemPerPage(){
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      return parseInt(windowH/objH);
    },
    onResize: function () {
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      this.itemPerPage = parseInt(windowH/objH);
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    async showAddCampaign() {
		await (this.addCampaignData = {
			"name": "",
			"channel": "",
			"segment": {
			"id": ""
			},
			"description": ""
		})
    this.getSegList()
    },
    async getSegList(){
    await SegmentDataService.shortList()
		.then(response => {
			this.segmentList = response.data;
			if (this.addCampaignData.name == "") {
				if(this.segmentList.length > 0){
					this.addCampaignData.segment = this.segmentList[0].id;
					this.addCampaignDialog = true;  
				}
				else{
					this.showErrorDialog(i18n.t('CAMPAIGN.ERROR'));
				}
			}
		});
    },
    onEmitAddCampaignDialog(val) {
      this.addCampaignDialog = false;
      if (val) {
        this.showSuccessDialog();
        this.getListData()
      }
    },
    onEmitAddCampaignData(data) {
      data.segmentName = data.segment.name;
      data.channelName = this.replaceChannel(data.channel);
      data.statusName = this.replaceStatus(data.status);
      data.ownerName = data.owner.last_name + " " + data.owner.first_name;
      this.campaignList.unshift(data);
      this.selectedRows = [];
    },
    showEditCampaign(data) {
      this.editCampaignDialog = true;
      this.has_cam_line = data.has_cam_line;
      this.has_ai_setting = data.has_ai_setting;
      this.editCampaignData = {
        "id": data.id,
        "name": data.name,
        "channel": data.channel,
        "segment": {
          "id": data.segment.id,
          "name": data.segment.name
        },
        "description": data.description,
        "status": data.status ? data.status:"PROPOSED",
        "type": data.type ? data.type:null,
        "end_time": data.end_time? data.end_time:null,
        "start_time": data.start_time? data.start_time :null,
      };
    },
    onEmitEditCampaignDialog(val) {
      this.editCampaignDialog = false;
      if (val) {
        this.showSuccessDialog();
      }
    },
    onEmitEditCampaignData(data) {
      data.segmentName = data.segment.name;
      data.channelName = this.replaceChannel(data.channel);
      data.statusName = this.replaceStatus(data.status);
      data.ownerName = data.owner.last_name + " " + data.owner.first_name;
      let index = this.campaignList.findIndex(i => {return i.id==data.id});
      this.campaignList.splice(index, 1, data);
    },
    // Message Dialog
    onDelete(id) {
      this.delId = id;
      this.delIndex = this.campaignList.findIndex(i => {return i.id==id});
      this.delMode = "DELETE";
      this.showMessageDialog(i18n.t('CAMPAIGN.MESSAGE'));
    },
    onBulkDelete() {
      this.selectedRows.forEach(item => {
        this.delIds.push(item.id);
      });
      this.delMode = "BULKDELETE";
      this.showMessageDialog(i18n.t('CAMPAIGN.MESSAGE_2'));
    },
    onCopy(copyData) {
      this.getSegList()
      this.addCampaignData = {
        "name": copyData.name,
        "channel": copyData.channel,
        "segment": copyData.segment.id,
        "description": copyData.description
      }
      this.addCampaignDialog = true;
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if (this.delMode == "DELETE") {
          CampaignDataService.delete(this.delId)
          .then((response) => {
            if(response.data){
              this.showErrorDialog(i18n.t('CAMPAIGN.ERROR_2'));
            }else{
              this.campaignList.splice(this.delIndex, 1);
              this.showSuccessDialog();
              this.currentData = this.currentData - 1
              this.pageDataCount = this.pageDataCount - 1
            }
          });
        } else if (this.delMode == "BULKDELETE") {
          CampaignDataService.bulkDelete(this.delIds)
          .then((response) => {
            if(response.data){
              this.delIds = [];
              this.selectedRows = [];
              this.showErrorDialog(i18n.t('CAMPAIGN.ERROR_3')+' : ' +response.data.name_list);
            }else{
              this.delIds.forEach(id => {
              let index = this.campaignList.findIndex(i => { return i.id == id })
              this.campaignList.splice(index, 1);
              this.currentData = this.currentData - 1
              this.pageDataCount = this.pageDataCount - 1
            })
            this.delIds = [];
            this.selectedRows = [];
            this.showSuccessDialog();
            }
          });
        }
        this.delMode = "";
      }
    },
    replaceChannel(key) {
      let index = this.campaign_channelList.findIndex(item => {return item.key == key});
      return this.campaign_channelList[index].name;
    },
    replaceStatus(key) {
      let index = this.campaignStatusList.findIndex(item => {return item.key == key});
      return this.campaignStatusList[index].name;
    },
  },
  watch:{
    "page":async function () {      
      await this.getListData()  
    },
    "search":async function () {      
      if (!this.awaitingSearch) {
				setTimeout(() => {
          this.page = 1
					this.getListData();
					this.awaitingSearch = false;					
				}, 1000);
				}
				this.awaitingSearch = true;  
    },   
    "sortByDesc":async function () {           
      this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
      for(let i in this.sortByDesc)
      {
        if(this.sortByDesc[i] == true)
        {
          this.sortByForSearch[i] = '-'+this.sortByForSearch[i]          
        }
      }
        await this.getListData()
    },
  }
};
</script>