<template lang='pug'>
    #Campaign.main-page.detail-page
        loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
        div.height_100(v-show='!loading')
            v-breadcrumbs.pa-0.px-3.pb-2.ellipsis(:items='breadcrumbs')
            v-row#page-head.d-flex.align-center.my-0
                v-col(cols='6')
                    .d-flex.align-center
                        h1.my-0.d-block.ellipsis(:title='campaginDetail.name') {{ campaginDetail.name }}
                v-col(cols='6')
                    v-row.justify-end(no-gutters='no-gutters')
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='onDelete' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.DELETE')}}
                        v-col.ml-2(cols='auto')
                            .btn-group
                                v-btn(@click='onChangeOwn()' width='100%' height='30' color='white' depressed tile dark)
                                    span {{$t('GENERAL.CHANGE_OWNER')}}
            #page-inner.pa-3.pt-0
                v-card.card-preface.card-shadow.px-0.py-6(flat)
                    v-row
                        v-col.px-2.px-md-4.py-0(cols='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='24') mdi-bullhorn-outline
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CAMPAIGN_DETAIL.CHANNEL')}}
                                    .f-text-highlight.mb-0.ellipsis(v-if='campaginDetail.channel != null') {{ replaceChannel(campaginDetail.channel) }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='24') mdi-account-multiple
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CAMPAIGN_DETAIL.PEOPLE')}}
                                    .f-text-highlight.mb-0.ellipsis {{ campaginDetail.people }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='3')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-icon.mt-3.mb-0.mr-2
                                    v-icon(size='24') mdi-chart-pie
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CAMPAIGN_DETAIL.SEGMENT')}}
                                    a.t-primary.f-text-highlight.mb-0.ellipsis(v-if='campaginDetail.segment != null' :href="'/Segment/'+campaginDetail.segment.id" color='black' text) {{ campaginDetail.segment.name }}
                        v-col.px-2.px-md-4.py-0.border-left(cols='3')
                            v-list-item.d-flex.align-center.pa-0
                                v-list-item-avatar.ma-0.mr-2(color='aliceBlue')
                                    span.mx-auto(v-if="!isPhoto") {{ changeOwnData.last_name }}
                                    img(v-else :src='changeOwnData.usersetting.photo' :alt='changeOwnData.last_name')
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CAMPAIGN_DETAIL.OWNER')}}
                                    a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(changeOwnData.email)' v-if='changeOwnData.first_name != null') {{ changeOwnData.last_name + " " + changeOwnData.first_name }}
                                    a.t-primary.mb-0.f-text-highlight.ellipsis(:href='onMail(changeOwnData.email)' v-else) {{ changeOwnData.last_name }}
                v-row.campaign-description.mt-5(no-gutters='no-gutters')
                    v-card.card-preface.card-shadow.px-0.py-6(flat style='width:100%')
                        v-col(cols='12')
                            v-list-item.d-flex.align-center.px-0
                                v-list-item-content.pa-0
                                    v-card-subtitle.pa-0.t-lightgary {{$t('CAMPAIGN_DETAIL.DESCRIPTION')}}
                                        v-btn(v-if='isDescReadonly' style='float:right' width='70' text color='gary' @click='onDescEditDetail()')
                                            v-icon(size='20') icon-edit
                                            span {{$t('GENERAL.EDIT')}}
                                        v-btn.ml-3(v-if='!isDescReadonly' style='float:right' width='70' text color='success' @click='confirmDescEditDetail()')
                                            v-icon(size='20') icon-check
                                            span {{$t('GENERAL.SAVE')}}
                                        v-btn(v-if='!isDescReadonly' style='float:right' width='70' text color='gary' @click='cancelDescEditDetail()')
                                            v-icon(size='20') icon-cancel
                                            span {{$t('GENERAL.CANCEL')}}
                                    v-textarea.textarea-style(v-model='campaginDetail.description' solo no-resize :readonly='isDescReadonly' dense='dense' rows='3')
                v-row.campaign-info.mt-5.pb-5(no-gutters='no-gutters')
                    v-col(cols='12')
                        v-card.deal-tab-card(flat)
                            v-tabs.main-tabs(v-model='tab' background-color='transparent' show-arrows)
                                v-tabs-slider
                                v-tab(v-if='haveSetOpenAI == true' v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                v-tab(v-if='haveSetOpenAI == false' v-for='item in tabs_without_AI' :key='item.key' :href='`#tab-${item.id}`' @click='getTabName(item.key)')
                                    | {{ item.name }}
                                v-row.justify-md-end.mr-8.mt-1(v-if='tab == "tab-3" && ai_valid && !ai_loaging' no-gutters='no-gutters')
                                    v-btn.mr-2(text height='38' color='gary' :title=`$t('GENERAL.REFRESH')` @click='refresh_ai')
                                        v-icon mdi-refresh
                                        span {{$t('GENERAL.REFRESH')}}
                                    v-btn(v-if='!isAiSending' style='float:right' width='100'  color='success' @click='generate_btn()' depressed :dark='current_page_ai_finish' :disabled='!current_page_ai_finish')
                                        span {{$t("AL_MAIL.GENERATE")}}
                                // - setup 
                                v-tab-item.pa-5(key='setup' value='tab-1' :transition='false' :reverse-transition='false')
                                    v-form(v-model='valid' ref='formCampaign')
                                        v-row(no-gutters)
                                            v-col.pr-md-4(md='3').my-1
                                                v-row(no-gutters class="campaignRowExtraCss")
                                                    v-col.pr-md-4(md='12').my-1
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.NAME')}}
                                                            v-text-field(v-model='campaginDetail.name' solo :readonly='isReadonly' :rules="formRules.nameRule" required="required")
                                                                template(v-slot:append)
                                                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil' @click='confirmEditDetail()') icon-check
                                                                    v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                    v-icon(size='18' v-if='!isEdit' @click='onEditDetail()') icon-edit
                                                                    v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary')
                                                    v-col.pr-md-4(md='12').my-1
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.CHANNEL')}}
                                                            v-select.no-border( v-model='campaginDetail.channel' solo :items='channelList' :readonly='isReadonly' item-text='name' item-value='key' :class='!isEdit ? "remove-arrow" : ""' @change='changeChannel' :menu-props='{closeOnClick:true}')
                                                                template(v-slot:append-outer)
                                                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil' @click='confirmEditDetail()') icon-check
                                                                    v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                    v-icon(size='18' v-if='!isEdit' @click='onEditDetail()') icon-edit
                                                                    v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary')
                                                    v-col.pr-md-4(cols='12').my-1
                                                        .d-flex.align-center.justify-space-between.input-has-label
                                                            .d-inline-flex.align-center.w-100
                                                                label {{$t('CAMPAIGN_DETAIL.SEGMENT')}}
                                                                .d-flex.justify-space-between.ellipsis.w-100(v-if='isReadonly && campaginDetail.segment != null || changeSegment')
                                                                    a.t-primary.ellipsis.py-2(v-if='campaginDetail.segment != null' :href="'/Segment/' + campaginDetail.segment.id" color='black' text) {{ segmentName }}
                                                                    v-icon(size='18' v-if='isReadonly && !changeSegment' @click='onEditDetail()') icon-edit
                                                                v-autocomplete.ellipsis(v-else v-model='campaginDetail.segment' solo :items="segmentList" item-text="name" item-value="id" :readonly='changeSegment')
                                                                    template(v-slot:append-outer)
                                                                        v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil && !changeSegment' @click='confirmEditDetail()') icon-check
                                                                        v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil && !changeSegment' @click='cancelEditDetail()') mdi-window-close  
                                                                        v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary')
                                                    v-col.pr-md-4(md='12').my-1
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.TYPE')}}
                                                            v-select.no-border( v-model='campaginDetail.type' solo :items='campaignTypeList' :readonly='isReadonly' item-text='name' item-value='key' :class='!isEdit ? "remove-arrow" : ""' @change='changeChannel' :menu-props='{closeOnClick:true}')
                                                                template(v-slot:append-outer)
                                                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil' @click='confirmEditDetail()') icon-check
                                                                    v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                    v-icon(size='18' v-if='!isEdit' @click='onEditDetail()') icon-edit
                                                                    v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary')
                                                    v-col.pr-md-4(md='12').my-1
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.START_TIME')}}
                                                            v-text-field.w-100(v-if='isReadonly' v-model='campaginDetail.start_time' solo :readonly='isReadonly')
                                                                template(v-slot:append)
                                                                    v-icon(size='18' v-if='isReadonly' @click='onEditDetail()') icon-edit
                                                            v-menu(v-if='!isReadonly' ref="menu_start_time" v-model="menu_start_time" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                                                template(v-slot:activator="{ on, attrs }")
                                                                    v-text-field(v-model='campaginDetail.start_time' solo :readonly='isReadonly' v-bind="attrs" v-on="on")
                                                                        template(v-slot:append)
                                                                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil' @click='confirmEditDetail()') icon-check
                                                                            v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil' @click='cancelEditDetail()') mdi-window-close
                                                                            v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary') 
                                                                v-date-picker( v-model='campaginDetail.start_time'  @input='menu_start_time = false' no-title scrollable)  
                                                    v-col.pr-md-4(md='12').my-1
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.END_TIME')}}
                                                            v-text-field.w-100(v-if='isReadonly' v-model='campaginDetail.end_time' solo :readonly='isReadonly')
                                                                template(v-slot:append)
                                                                    v-icon(size='18' v-if='isReadonly' @click='onEditDetail()') icon-edit
                                                            v-menu(v-if='!isReadonly' ref="menu_end_time" v-model="menu_end_time" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                                                template(v-slot:activator="{ on, attrs }")
                                                                    v-text-field(v-model='campaginDetail.end_time' solo :readonly='isReadonly' v-bind="attrs" v-on="on")
                                                                        template(v-slot:append)
                                                                            v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil' @click='confirmEditDetail()') icon-check
                                                                            v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil' @click='cancelEditDetail()') mdi-window-close
                                                                            v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary')
                                                                v-date-picker( v-model='campaginDetail.end_time' mode="dateTime" @input='menu_end_time = false' no-title scrollable)
                                                    v-col.pr-md-4(md='12').my-1
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.STATUS')}}
                                                            v-select.no-border( v-model='campaginDetail.status' solo :items='campaignStatusList' :readonly='isReadonly' item-text='name' item-value='key' :class='!isEdit ? "remove-arrow" : ""' :menu-props='{closeOnClick:true}')
                                                                template(v-slot:append-outer)
                                                                    v-icon.mr-1(size='18' color="green" v-if='isEdit && !loading_campaginDeatil' @click='confirmEditDetail()') icon-check
                                                                    v-icon(size='18' color="red" v-if='isEdit && !loading_campaginDeatil' @click='cancelEditDetail()') mdi-window-close  
                                                                    v-icon(size='18' v-if='!isEdit' @click='onEditDetail()') icon-edit
                                                                    v-progress-circular(size='18' v-if='loading_campaginDeatil' indeterminate color='primary')
                                            v-col.pr-md-4(md='9').my-1
                                                v-col.py-1(md='12')
                                                    p.d-flex.align-center.input-has-label
                                                        label {{$t('CAMPAIGN_DETAIL.TEMPLATE')}}
                                                        v-select.no-border(v-if='campaginDetail.channel == "EMAIL"' v-model='emailTemplate' solo :items='emailTemplateList' item-text='template_name' item-value='id' @change='changeTemplate' :menu-props='{closeOnClick:true}')
                                                        v-select.no-border(v-else solo v-model='smsTemplate' :items='smsTemplateList' item-text='template_name' item-value='id' @change='changeTemplate' :menu-props='{closeOnClick:true}')
                                                v-col(cols='12').px-0
                                                    .d-flex.align-center
                                                        v-icon.mr-1(size='25') mdi-send-outline
                                                        h3.my-0.d-block.ellipsis(v-if='campaginDetail.channel == "EMAIL"') {{$t('CAMPAIGN_DETAIL.EMAIL_CONTENT')}}
                                                        h3.my-0.d-block.ellipsis(v-else) {{$t('CAMPAIGN_DETAIL.SMS_CONTENT')}}
                                                        v-row.justify-md-end(v-if='isO365 || campaginDetail.channel == "SMS"' no-gutters='no-gutters')
                                                            v-btn(v-if='!isSending' @click='saveContextEdit' width='80' height='32' color='red' class="mr-2" depressed dark)
                                                                v-icon.mr-1(size='14') mdi-content-save
                                                                span {{$t('GENERAL.SAVE')}}
                                                            v-btn(v-if='!isSending' @click='onPreview' width='80' height='32' color='green' depressed dark)
                                                                v-icon.mr-1(size='14') mdi-eye
                                                                span {{$t('CAMPAIGN_DETAIL.PREVIEW')}}
                                                            v-col.py-1(v-else cols='4' style='padding:1rem 1rem 0 1rem')
                                                                h5.my-0.d-block.ellipsis(style='float:right') {{ done }} / {{ total }} {{$t('CAMPAIGN_DETAIL.SENTING')}}
                                                v-row(v-if='!isO365 && campaginDetail.channel == "EMAIL"' no-gutters)
                                                    span(v-if="!invalidO365Token") {{$t('CAMPAIGN_DETAIL.NO_O365')}}
                                                    span(v-if="invalidO365Token") {{$t('CAMPAIGN_DETAIL.INVALID_O365_TOKEN')}}
                                                    a.t-primary.mx-4.ellipsis(:href="'/Setting#EmailSync'" color='black' text) {{$t('CAMPAIGN_DETAIL.GO_SETTING')}}
                                                v-row(no-gutters v-if='campaginDetail.channel == "EMAIL" && isO365' class='template')
                                                    v-col.py-1(cols='12' style='padding:1rem 1rem 0 1rem')
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.SUBJECT')}}
                                                            v-text-field(v-model='subject' solo dense)
                                                    v-col(style='padding:1rem 1rem 0 1rem' cols='12')
                                                        p.d-flex.align-center
                                                            v-file-input.pt0(ref='formEmailFile' :label=`$t('CAMPAIGN_DETAIL.ATTACHMENT')` placeholder='' :rules="formRules.fileRule" v-model='attachments' dense multiple show-size small-chips counter truncate-length="15" @change='onChangeFile')
                                                    v-col.mt-5(cols='12' style='padding:1rem 1rem 0 1rem')
                                                        vue-editor(v-model='emailContext' :editor-toolbar='customToolbar' :editorOptions='editorSettings' :customModules='customModulesForEditor' @selection-change='onSelectionChange' @text-change='onTextChange')
                                                        .editor-custom-toolbar.text-right
                                                            v-menu(top :close-on-click='true' :close-on-content-click='false')
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    v-btn.px-3(v-bind='attrs' v-on='on' text :ripple='false')
                                                                        v-icon.mr-1(size='15') icon-add
                                                                        span Fields
                                                                v-card.fields
                                                                    v-tabs(v-model='emailTemplateTabs' hide-slider)
                                                                        //- v-tab.px-0(href="#tab-time" :ripple='false')
                                                                        //-     v-icon(size='19') icon-time
                                                                        //- v-tab.px-0(href="#tab-dollar" :ripple='false')
                                                                        //-     v-icon(size='19') icon-dollar
                                                                        v-tab.px-0(href="#tab-user" :ripple='false')
                                                                            v-icon(size='19') icon-user-1
                                                                        //- v-tab.px-0(href="#tab-building" :ripple='false')
                                                                        //-     v-icon(size='19') icon-building
                                                                        //- v-tab.px-0(href="#tab-dots" :ripple='false')
                                                                        //-     v-icon(size='19') icon-dots
                                                                    v-tabs-items(v-model='emailTemplateTabs')
                                                                        //- v-tab-item(value='tab-time' transition='none')
                                                                        //- v-tab-item(value='tab-dollar' transition='none')
                                                                        //-     v-list.d-flex.flex-column(dense)
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.name')") Deal Name
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.owner')") Owner
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.closed_date')") Closed Date
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.closed_reason')") Closed Reason
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.amount')") Amount
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.primary_contact')") Primary Contact
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.organization')") Organization
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.stage_type')") Pipeline
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.current_stage')") Current Stage
                                                                        //-         v-btn(text @click="addEmailTemplateFields('opportunity.customer_budget')") Customer Budget
                                                                        v-tab-item(value='tab-user' transition='none')
                                                                            v-list.d-flex.flex-column(dense)
                                                                                v-btn(text @click="addEmailTemplateFields('contact.first_name')") First Name
                                                                                v-btn(text @click="addEmailTemplateFields('contact.last_name')") Last Name
                                                                                v-btn(text @click="addEmailTemplateFields('contact.owner')") Owner
                                                                                v-btn(text @click="addEmailTemplateFields('contact.level')") Level
                                                                                v-btn(text @click="addEmailTemplateFields('contact.department')") Department
                                                                                v-btn(text @click="addEmailTemplateFields('contact.organization')") Organization
                                                                                v-btn(text @click="addEmailTemplateFields('contact.email')") Email
                                                                                v-btn(text @click="addEmailTemplateFields('contact.mobile_phone')") Mobile Phone
                                                                                v-btn(text @click="addEmailTemplateFields('contact.office_phone')") Office Phone
                                                                                v-btn(text @click="addEmailTemplateFields('contact.birthdate')") Birthday
                                                                                v-btn(text @click="addEmailTemplateFields('contact.title')") Title
                                                                        //- v-tab-item(value='tab-building' transition='none')
                                                v-row(no-gutters v-if='campaginDetail.channel == "SMS"' class='template')
                                                    v-col.py-1(cols='12' style='padding:1rem 1rem 0 1rem')
                                                        p.d-flex.align-center.input-has-label
                                                            label {{$t('CAMPAIGN_DETAIL.SMS_CONTENT_2')}}
                                                            v-textarea(v-model='smsContext' solo dense ref="textarea"  class='smsTextArea')
                                                        v-row(no-gutters)
                                                            v-col.d-flex.align-center.justify-end(cols='12')
                                                                v-menu(top :close-on-click='true' :close-on-content-click='false')
                                                                    template(v-slot:activator='{ on, attrs }')
                                                                        v-btn.px-3(v-bind='attrs' v-on='on' text :ripple='false')
                                                                            v-icon.mr-1(size='15') icon-add
                                                                            span Fields
                                                                    v-card.fields
                                                                        v-tabs(v-model='smsTemplateTabs' hide-slider)
                                                                            //- v-tab.px-0(href="#tab-time" :ripple='false')
                                                                                v-icon(size='19') icon-time
                                                                            //- v-tab.px-0(href="#tab-dollar" :ripple='false')
                                                                                v-icon(size='19') icon-dollar
                                                                            v-tab.px-0(href="#tab-user" :ripple='false')
                                                                                v-icon(size='19') icon-user-1
                                                                            //- v-tab.px-0(href="#tab-building" :ripple='false')
                                                                                v-icon(size='19') icon-building
                                                                            //- v-tab.px-0(href="#tab-dots" :ripple='false')
                                                                                v-icon(size='19') icon-dots
                                                                        v-tabs-items(v-model='smsTemplateTabs')
                                                                            //- v-tab-item(value='tab-time' transition='none')
                                                                            //- v-tab-item(value='tab-dollar' transition='none')
                                                                                v-list.d-flex.flex-column(dense)
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.name')") Title
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.owner')") Owner
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.closed_date')") Closed Date
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.closed_reason')") Closed Reason
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.amount')") Amount
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.primary_contact')") Primary Contact
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.organization')") Organization
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.stage_type')") Pipeline
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.current_stage')") Current Stage
                                                                                    v-btn(text @click="addSMSTemplateFields('opportunity.customer_budget')") Customer Budget
                                                                            v-tab-item(value='tab-user' transition='none')
                                                                                v-list.d-flex.flex-column(dense)
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.first_name')") First Name
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.last_name')") Last Name
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.owner')") Owner
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.level')") Level
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.department')") Department
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.organization')") Organization
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.email')") Email
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.mobile_phone')") Mobile Phone
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.office_phone')") Office Phone
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.birthdate')") Birthday
                                                                                    v-btn(text @click="addSMSTemplateFields('contact.title')") Title
                                                                            //- v-tab-item(value='tab-building' transition='none')
                                // - analysis -
                                v-tab-item.pa-3(key='analysis' value='tab-2' :transition='false' :reverse-transition='false')
                                    .mt-2.mb-14
                                        .d-flex.align-center.justify-space-between.mb-4
                                            h3.mt-2.mb-4.d-flex.align-center
                                                .cube-icon.small.bg-og.mr-2
                                                    v-icon(size='19' color='white') fas fa-chart-pie
                                                span {{$t('CAMPAIGN_DETAIL.CAMPAIGN_Analysis_Chart')}}                                            
                                        v-row.align-center.justify-space-between(v-if='isDoughnut === true')
                                            v-col(cols='12' sm='6' md='3' v-if='analysisType === "EMAIL" | analysisType === "BOTH"')
                                                bar-chart(v-if='isAnalysis' :chart-data="doughnutDataAll" :options='doughnutOptionsAll')
                                            v-col(cols='12' sm='6' md='3' v-if='analysisType === "EMAIL" | analysisType === "BOTH"')
                                                bar-chart(v-if='isAnalysis' :chart-data="doughnutDataOpen" :options='doughnutOptionsOpen')
                                            v-col(cols='12' sm='6' md='3' v-if='analysisType === "EMAIL" | analysisType === "BOTH"')
                                                bar-chart(v-if='isAnalysis' :chart-data="doughnutDataClick" :options='doughnutOptionsClick')
                                            v-col(cols='12' sm='6' md='3' v-if=' analysisType === "BOTH"')
                                                bar-chart(v-if='isAnalysis' :chart-data="doughnutDataSMS" :options='doughnutOptionsSMS')                                        
                                            v-col(cols='12' sm='6' md='3' v-if='analysisType === "SMS"').mx-auto
                                                bar-chart(v-if='isAnalysis' :chart-data="doughnutDataSMS" :options='doughnutOptionsSMS')
                                        v-row(v-if='isDoughnut === false')
                                            v-col.d-flex.align-center.justify-center.pa-0( cols='12' style='height: 132px;')
                                                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                    h5.ma-0.t-black {{$t('CAMPAIGN_DETAIL.NO_DATA')}}
                                    .mt-2.mb-10
                                        .d-flex.align-center.justify-space-between.mb-4
                                            h3.mt-2.mb-4.d-flex.align-center
                                                .cube-icon.small.bg-og.mr-2
                                                    v-icon(size='19' color='white') fas fa-list-ul
                                                span {{$t('CAMPAIGN_DETAIL.CAMPAIGN_Analysis_Statistics')}}
                                        v-row(v-if='analysis.email.length != 0 | analysis.sms.length != 0')
                                            v-col.px-0(cols='auto' )
                                                v-row
                                                    v-col.px-0(cols='9' sm='6' md='9')
                                                        v-card-title.pa-0 {{$t('GENERAL.MAIL')}}
                                                v-data-table.segment-table.ellipsis.mb-4(:headers='Header_emailCampaign' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='analysis.email' :search='search_emailCampaign'  :items-per-page='itemPerPage_emailCampaign' :page.sync='page_emailCampaign' @page-count='pageCount_emailCampaign = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' item-key='id' :loading='Loading_emailCampaign' loading-text="Loading..." )                                           
                                                    template(v-slot:item.name ="{ item }")
                                                        .ellipsis.d-flex.justify-space-around
                                                            //- a(:href='`/Campaign/${item.id}`' v-if='item.id') {{ item.name }}
                                                            a(:href='`/Campaign/${item.campaign_id}`' v-if='item.campaign_id') {{ item.name }}                                                             
                                                    template(v-slot:item.sent ="{ item }")
                                                        .ellipsis.d-flex.justify-space-around(v-if='item.open_and_link')
                                                            .t-pink.text-with-overline.pointer(@click='showContactList(item.id,"Success")' ) {{ item.try_count-item.error_count }}
                                                            span vs
                                                            .t-green {{ item.try_count }}
                                                    template(v-slot:item.open ="{ item }")
                                                        .ellipsis.d-flex.justify-space-around(v-if='item.open_and_link')
                                                            .t-pink.text-with-overline.pointer(@click='showContactList(item.id,"Open")')  {{ item.open_and_link.open }}
                                                            span vs
                                                            .t-green {{ item.try_count-item.error_count }}
                                                        .ellipsis.d-flex.justify-space-around(v-if='!item.open_and_link')                                                            
                                                            span {{$t('CAMPAIGN_DETAIL.NO_CAMPAIGN_DATA')}}                                                                                                                                                                   
                                                    template(v-slot:item.click ="{ item }")
                                                        .ellipsis.d-flex.justify-space-around(v-if='item.open_and_link')
                                                            .t-pink.text-with-overline.pointer(@click='showContactList(item.id,"Link")')  {{ item.open_and_link.link }}
                                                            span vs
                                                            .t-green {{ item.try_count-item.error_count }}
                                                    template(v-slot:item.end_time ='{ item }')
                                                        .ellipsis
                                                            span(:title='item.end_time') {{ item.end_time }}
                                                    template(v-slot:item.owner.full_name ='{ item }')
                                                        .ellipsis
                                                            a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.full_name') {{ item.owner.full_name }}
                                                v-pagination.py-1.mb-3(v-if='pageCount_emailCampaign >= 2' v-model='page_emailCampaign' :length='pageCount_emailCampaign' circle :total-visible="10") 
                                            v-col.px-0(cols='auto'  v-if='analysis.sms.length != 0')
                                                v-row
                                                    v-col.px-0(cols='9' sm='6' md='9')
                                                        v-card-title.pa-0 SMS
                                                v-data-table.segment-table.ellipsis(:headers='Header_SMSCampaign' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='analysis.sms' :search='search_smsCampaign' :items-per-page='itemPerPage_smsCampaign' :page.sync='page_smsCampaign' @page-count='pageCount_smsCampaign = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' item-key='id' :loading='Loading_smsCampaign' loading-text="Loading..." )
                                                    template(v-slot:item.name ="{ item }")
                                                        .ellipsis.d-flex.justify-space-around
                                                            //- a(:href='`/Campaign/${item.id}`' v-if='item.id') {{ item.name }}
                                                            a(:href='`/Campaign/${item.campaign_id}`' v-if='item.campaign_id') {{ item.name }}  
                                                    template(v-slot:item.try_count ="{ item }")
                                                        .ellipsis.d-flex.justify-space-around(v-if='item.try_count')
                                                            .t-pink.text-with-overline.pointer(@click='showContactList(item.id,"Success")') {{ item.try_count-item.error_count }}
                                                            span vs
                                                            .t-green {{ item.try_count }}
                                                        .ellipsis.d-flex.justify-space-around(v-if='!item.try_count')
                                                            span {{$t('CAMPAIGN_DETAIL.NO_CAMPAIGN_DATA')}} 
                                                    template(v-slot:item.end_time ='{ item }')
                                                        .ellipsis
                                                            span(:title='item.end_time') {{ item.end_time }}
                                                    template(v-slot:item.owner.full_name ='{ item }')
                                                        .ellipsis
                                                            a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.full_name') {{ item.owner.full_name }}
                                                v-pagination.py-1.mb-3(v-if='pageCount_smsCampaign >= 2' v-model='page_smsCampaign' :length='pageCount_smsCampaign' circle :total-visible="10") 
                                        v-row(v-else)
                                            v-col.d-flex.align-center.justify-center.pa-0( cols='12' style='height: 132px;')
                                                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                                                    h5.ma-0.t-black {{$t('CAMPAIGN_DETAIL.NO_DATA')}} 
                                // - AI MAIL -
                                v-tab-item.pa-5(v-if='haveSetOpenAI == true && !has_contact'  key='ai' value='tab-3' :transition='false' :reverse-transition='false')
                                    v-row(v-if="!has_contact" style='height:300px;') 
                                        v-col.d-flex.align-center.justify-center.pa-0( height='100%')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='30%' height='30%')
                                                h5.ma-0.t-black {{$t('AL_MAIL.NO_CONTACT')}}
                                v-tab-item.pa-5(v-if='haveSetOpenAI == true && has_contact == true'  key='ai' value='tab-3' :transition='false' :reverse-transition='false')
                                    v-row(v-if="!has_contact" height='100%') 
                                        v-col.d-flex.align-center.justify-center.pa-0( height='100%')
                                            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='30%' height='30%')
                                                h5.ma-0.t-black {{$t('CASE.NO_CASE_STAGE')}}
                                    v-row.d-flex.align-center.justify-center(v-if='ai_loaging' style='margin-top:180px;margin-bottom:180px;')
                                        v-progress-circular.mr-2(model-value="20" indeterminate)
                                        h1 Loading...
                                    v-row(v-else)
                                        v-col(cols="3")
                                            v-data-table(
                                                :headers='campaginDetail.channel == "EMAIL"?contactHeader : contactHeader_phone'
                                                :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
                                                :items='contacts'
                                                :items-per-page='itemPerPage_contacts'
                                                :page.sync='page_contacts'
                                                :item-class= "get_select_row_class"
                                                @page-count='pageCount_contacts = $event'
                                                :sort-desc.sync='sortByDesc'
                                                :sort-by.sync="sortBy"
                                                height='530px'
                                                hide-default-footer='hide-default-footer'
                                                item-key='id' 
                                                v-model="selectedRows" 
                                                :loading='false' 
                                                loading-text="Loading..."
                                                fixed-header
                                                @click:row="select_contact($event)"
                                            )
                                                template(v-slot:item.__chineseName__ ='{ item }')
                                                    div(class=' d-flex justify-left' :title='item.__chineseName__' ) 
                                                        a(v-if='!!item.__chineseName__' :href="'/Contacts/' + item.id" class='ellipsis') {{ item.__chineseName__ }}
                                                //- 電子郵件 email
                                                template(v-slot:item.email='{ item }')
                                                    div(class=' d-flex justify-left position-relative' :title='item.email' )
                                                        span(class='ellipsis' :title='item.email') {{ item.email }}
                                                        //- a(class='ellipsis' :title='item.email' :href='onMail(item.email)' ) {{ item.email }}
                                                template(v-slot:item.mobile_phone='{ item }')
                                                    div(class=' d-flex justify-left position-relative' :title='item.mobile_phone' )
                                                        span(class='ellipsis' :title='item.mobile_phone') {{ item.mobile_phone }}
                                                        //- a(class='ellipsis' :title='item.mobile_phone' :href='onCall(item.mobile_phone)' ) {{ item.mobile_phone }}
                                                template(v-slot:item.status='{ item }')
                                                    div(class=' d-flex justify-center position-relative' :title='get_ai_message_status(item.status)' )
                                                        span(class='ellipsis' :title='get_ai_message_status(item.status)' ) {{ get_ai_message_status(item.status) }}
                                            v-pagination.py-1.mb-3( v-model='page_contacts' :length='pageCount_contacts' circle :total-visible="8")
                                        v-row(v-if='!isO365 && campaginDetail.channel == "EMAIL"' no-gutters)
                                            span(v-if="!invalidO365Token") {{$t('CAMPAIGN_DETAIL.NO_O365')}}
                                            span(v-if="invalidO365Token") {{$t('CAMPAIGN_DETAIL.INVALID_O365_TOKEN')}}
                                            a.t-primary.mx-4.ellipsis(:href="'/Setting#EmailSync'" color='black' text) {{$t('CAMPAIGN_DETAIL.GO_SETTING')}}
                                        //- v-row(v-if='!canSetSmsVendor && campaginDetail.channel == "SMS"' no-gutters)
                                        //-     span(v-if="smsVendor!='MITAKE'") {{$t("AL_MAIL.SMS_VENDOR")}}
                                        //-     a.t-primary.mx-4.ellipsis(:href="'/Setting#smsVendor'" color='black' text) {{$t('CAMPAIGN_DETAIL.GO_SETTING')}}
                                        v-col(v-else cols="9")
                                                v-row(style='display:inline;')
                                                    .d-flex.align-center
                                                        v-btn.process-box-open(width='40' height='40' depressed fab outlined @click='switchStepContent()')
                                                            v-icon(size='14' v-if='stepContent === true') icon-arrow-top
                                                            v-icon(size='14' v-else) icon-arrow-btm
                                                        h3.my-0.ml-1.d-flex.ellipsis {{$t('AL_MAIL.SETTING')}}
                                                        v-row.justify-md-end()
                                                            v-btn(v-if='!isAiSending && has_ai_mail' @click='sendAllAiMessage' width='90' height='32' color='green' depressed :dark='current_page_ai_finish' :disabled='!current_page_ai_finish')
                                                                span {{$t('AL_MAIL.SEND_ALL')}}
                                                v-row.mt-3(no-gutters v-show="stepContent" class='template')
                                                    v-col
                                                        v-form(v-model="ai_valid" ref="ai_setting")
                                                            v-col.pr-md-4(md='4').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('AL_MAIL.TONE')}}
                                                                    v-select.no-border.v-text-field_low_z-index(v-model='ai_tone' solo :items='ai_tone_list'  item-text='name' item-value='key' :rules='formRules.fieldRules'  @change='ai_field_valid()' :menu-props='{closeOnClick:true}')
                                                            v-col.pr-md-4(md='4' v-if='campaginDetail.channel == "EMAIL"').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('AL_MAIL.LENGTH')}}
                                                                    v-select.no-border.v-text-field_low_z-index(v-model='ai_length' solo :items='ai_length_list'  item-text='name' item-value='key' :rules='formRules.fieldRules' @change='ai_field_valid()' :menu-props='{closeOnClick:true}')
                                                            v-col.pr-md-4(md='4').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('AL_MAIL.LANGUAGE')}}
                                                                    v-select.no-border.v-text-field_low_z-index(v-model='ai_lang' solo :items='ai_lang_list'  item-text='name' item-value='key' :rules='formRules.fieldRules'  @change='ai_field_valid()' :menu-props='{closeOnClick:true}')
                                                            v-col.pr-md-4(md='12' v-if='campaginDetail.channel == "EMAIL"').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('CAMPAIGN_DETAIL.SUBJECT')}}
                                                                    v-text-field.v-text-field_low_z-index(v-model='ai_subject' solo dense :rules='formRules.fieldRules'  @change='ai_field_valid()' )
                                                            v-col.pr-md-4(cols='12' v-if='campaginDetail.channel == "EMAIL"').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('CAMPAIGN_DETAIL.ATTACHMENT')}}
                                                                    v-file-input.pt0(ref='formAiEmailFile'  :placeholder='att_placeholder' :rules="formRules.fileRule" v-model='ai_attachments' dense multiple show-size small-chips counter truncate-length="15" @change='onChangeFile')
                                                            v-col.pr-md-4(md='12').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('AL_MAIL.TIP')}}
                                                                    v-textarea(v-model='ai_prompt' :rules='formRules.tipRules' @change='ai_field_valid()' counter)
                                                            v-col.pr-md-4(md='12').my-1.pa-0
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('AL_MAIL.SIGN')}}
                                                                    v-textarea(v-model='ai_sign' :rules='formRules.fieldRules' @change='ai_field_valid()')
                                                v-col.mt-2(v-if='!isAiSending && has_ai_mail && current_message_contact.status != ""' cols='12').px-0
                                                    .d-flex.align-center
                                                        v-icon.mr-1(size='25') mdi-send-outline
                                                        h3.my-0.d-block.ellipsis(v-if='campaginDetail.channel == "EMAIL"') {{$t('CAMPAIGN_DETAIL.EMAIL_CONTENT')}}
                                                        h3.my-0.d-block.ellipsis(v-else) {{$t('CAMPAIGN_DETAIL.SMS_CONTENT')}}
                                                v-row.mt-1
                                                    v-row.justify-md-end.pr-9(v-if='((isO365 && campaginDetail.channel == "EMAIL") || campaginDetail.channel == "SMS") && has_ai_mail && current_message_contact.status != ""' no-gutters='no-gutters')
                                                            v-btn(v-if='!isAiSending && current_message_contact.status == "unsaved" ' @click='ai_undo_or_save_ContextEdit("undo")' width='80' height='32' color='red' class="mr-2" depressed dark)
                                                                v-icon.mr-1(size='14') mdi-refresh
                                                                span {{$t('AL_MAIL.UNDO')}}
                                                            v-btn(v-if='campaginDetail.channel == "EMAIL" && !isAiSending && current_message_contact && current_message_contact.status == "unsaved"' @click='ai_undo_or_save_ContextEdit("save")' width='80' height='32' color='red' class="mr-2" depressed dark)
                                                                v-icon.mr-1(size='14') mdi-content-save
                                                                span {{$t('GENERAL.SAVE')}}
                                                            v-btn(v-if='campaginDetail.channel == "SMS" && !isAiSending && current_message_contact && current_message_contact.status == "unsaved"' @click='ai_undo_or_save_ContextEdit("save")' width='80' height='32' color='red' class="mr-2" depressed dark)
                                                                v-icon.mr-1(size='14') mdi-content-save
                                                                span {{$t('GENERAL.SAVE')}}
                                                            v-btn(v-if='!isAiSending && current_message_contact.status != "isRemake"' @click='ai_onPreview' width='80' height='32' color='green' depressed dark)
                                                                v-icon.mr-1(size='14') mdi-eye
                                                                span {{$t('CAMPAIGN_DETAIL.PREVIEW')}}
                                                            v-btn.ml-2(v-if='!isAiSending' @click='ai_remakeContext_btn()' width='90' height='32' color='green' depressed :dark="current_message_contact.status == 'isRemake' ? false:true" :disabled='current_message_contact.status == "isRemake"') {{current_message_contact.status == "isRemake" ? $t('AL_MAIL.REMAKEING'):$t('AL_MAIL.REMAKE')}}
                                                                v-icon(size='16' color='black' :disabled='true' v-if='current_message_contact.status == "isRemake"') fas fa-circle-notch fa-spin
                                                            v-col.py-1(v-else cols='4' style='padding:1rem 1rem 0 1rem')
                                                    .d-flex.align-center(style='width:100vw;' v-if='has_ai_mail && !isAiSending && current_message_contact.status != "" && current_message_contact.status != "isRemake"')
                                                        v-btn(icon @click='go_next("left")')
                                                            v-icon icon-arrow-left
                                                        v-row(no-gutters v-if='campaginDetail.channel == "EMAIL" && isO365')
                                                            v-col.mt-5(cols='12')
                                                                vue-editor(v-model='ai_emailContext' :disabled="current_message_contact.status != 'isRemake' ? false:true" :editor-toolbar='customToolbar' :editorOptions='editorSettings' :customModules='customModulesForEditor' @selection-change='onSelectionChange' @text-change='onAiTextChange(ai_emailContext)')
                                                        v-row(no-gutters v-if='campaginDetail.channel == "SMS"')
                                                            v-col.mt-5(cols='12')
                                                                p.d-flex.align-center.input-has-label
                                                                    label {{$t('CAMPAIGN_DETAIL.SMS_CONTENT_2')}}
                                                                    v-textarea(v-model='ai_smsContext' rows='10' solo dense ref="textarea" class='smsTextArea' @input='onAiTextChange(ai_smsContext)')
                                                        v-btn(icon @click='go_next("right")')
                                                            v-icon icon-arrow-right
                                                    
                                                    v-col.blankaimail.d-flex.justify-center(v-else-if='(!has_ai_mail && !isAiSending|| (has_ai_mail && !isAiSending && (current_message_contact.status == ""|| current_message_contact.status == "isRemake")))' cols='12')
                                                        img(src="@/assets/images/Shutterstock.png" height="550px" )
                                                        div
                                                            span {{ has_ai_mail ? $t('AL_MAIL.REMAKEING') : $t('AL_MAIL.NO_AMIL')}}
                                                    v-col.blankaimail.d-flex.justify-center(v-else-if='has_ai_mail && isAiSending' cols='12' style='height:800px;')
                                                        img.fly.flyver(v-if='isAiSending' src="@/assets/images/fly.png" height="100px" )
                                                        div
                                                            span {{$t('AL_MAIL.SENDING')}} {{ ai_done }} / {{ ai_total }}
        v-dialog(v-model='changeOwnDialog' max-width='500' v-if='changeOwnDialog')
            change-own(@emitChangeOwnDialog='onEmitChangeOwnDialog' :owner-data='changeOwnData' :other-data='otherData' change='campaigns')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='emailCampaignDialog' width='720' content-class='statusDialog' v-if='emailCampaignDialog')
            email-campaign-dialog(@emitEmailCampaignDialog='onEmitEmailCampaignDialog' :email-data='emailData' :is_ai='tab=="tab-3" ? true : false' :att_placeholder='att_placeholder')
        v-dialog(v-model='smsCampaignDialog' width='480' content-class='statusDialog' v-if='smsCampaignDialog')
            sms-campaign-dialog(@emitSMSCampaignDialog='onEmitSMSCampaignDialog' :sms-data='smsData' :is_ai='tab=="tab-3" ? true : false')
        v-dialog(v-model='campaignContactList' max-width='1000' v-if='campaignContactList')
            campaign-contact-list(@emitAddSegContactDialog='onEmitAddSegContactDialog' :campaignlineId='campaignlineId' :selectType='selectType')
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import ImageResize from "quill-image-resize-vue";
    import DoughnutChart from '../assets/script/doughnutChart';
    import changeOwn from '@/components/Dialog/changeOwn';
    import emailEditor from '@/components/Common/emailEditor';
    import emailContactsDialog from '@/components/Dialog/emailContactsDialog';
    import emailAttachmentsDialog from '@/components/Dialog/emailAttachmentsDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog'; 
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import CampaignDataService from "@/services/CampaignDataService";
    import SegmentDataService from "@/services/SegmentDataService";
    import EmailTemplateDataService from "@/services/EmailTemplateDataService";
    import SMSTemplateDataService from "@/services/SMSTemplateDataService";
    import UpGPTDataService from "@/services/UpGPTDataService";
    import O365Service from "@/services/O365Service";
    import PreferenceService from "@/services/PreferenceService";
    import emailCampaignDialog from '@/components/Dialog/emailCampaignDialog';
    import smsCampaignDialog from '@/components/Dialog/smsCampaignDialog';
    import campaign_channelList from "@/array/the_campaignChannelList";
    import barChart from '../assets/script/barChart';
    import campaignContactList from "@/components/Dialog/Campaign/campaignContactList";
    import campaignTypeList from "@/array/the_campaignTypeList";
    import i18n from '/common/plugins/vue-i18n.js' 
    import campaignStatusList from "@/array/the_campaignStatusList";

    export default {
        components: {
            barChart,
            VueEditor,
            DoughnutChart,
            changeOwn,
            emailEditor,
            emailContactsDialog,
            emailAttachmentsDialog,
            messageDialog,
            successDialog,
            errorDialog,
            loadingOverlay,
            emailCampaignDialog,
            smsCampaignDialog,
            campaignContactList,
            campaignTypeList,
        },
        data() {
            return {
                campaignStatusList:campaignStatusList,
                campaignTypeList:campaignTypeList,
                menu_start_time: false,
                menu_end_time: false,
                tab: {},
                // setup
                loading_campaginDeatil: false,
                mode: '',
                isMore: false,
                isReadonly: true,
                isDescReadonly: true,
                isPhoto: true,
                isEdit: false,
                channelName: '',
                segmentName: '',
                originData: {},
                formRules: {
                    nameRule: [
                        (v) => !!v || i18n.t('RULE.RULE_R_CAMPAIGN'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    ],
                    fieldRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                    ],
                    tipRules: [
                        (v) => !!v || i18n.t('RULE.RULE_R'),
                        (v) => v.length<=500 || i18n.t('RULE.RULE_500'),
                    ],
                    // fileRule: [files => !files || !files.some(file => file.size > 2_097_152) || 'Attachment should be less than 2 MB!'],
                    fileRule: [files => !files || this.isFileAvailable || i18n.t('RULE.RULE_FILE')],
                },
                isFileAvailable: true,
                fileAmountSize: 0,
                emailContext: '',
                smsContext: '',
                templateIndex: '',
                emailTemplateList: [],
                smsTemplateList: [],
                emailTemplate: '',
                smsTemplate: '',
                customToolbar: [
                    [{ 'header': [false, 1, 2, 3, ] }],
                    ["bold", "italic", "underline"],
                    [{'align': ''}, {'align': 'center'}, {'align': 'right'}, ],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ 'color': [] }, { 'background': [] }],
                    ['link'],
                ],
                editorSettings: {
                    modules: {
                        imageResize: {}
                    }
                },
                customModulesForEditor: [
                    { alias: "imageResize", module: ImageResize }
                ],   
                o365AccountData: [],
                isO365: false,
                invalidO365Token: false,
                subject: '',
                attachments: [],
                emailTemplateTabs: '',
                smsTemplateTabs: '',
                isSending: true,
                total: 0,
                done: 0,
                campaignlineList: [],
                timeInterval: "",
                 // - email campaign table
                page_emailCampaign: 1,
                pageCount_emailCampaign: 0,
                itemPerPage_emailCampaign: 12,
                selectedRows_emailCampaign: [],
                Loading_emailCampaign: false,
                search_emailCampaign: '',
                // - sms campaign table
                page_smsCampaign: 1,
                pageCount_smsCampaign: 0,
                itemPerPage_smsCampaign: 12,
                selectedRows_smsCampaign: [],
                Loading_smsCampaign: false,
                search_smsCampaign: '',
                // 
                valid: true,
                loading: true,
                channelList: campaign_channelList,
                segmentList: [],
                campaginDetail: {},
                contextDetail: {},
                analysis: {
                    email: [
                        // {
                        //     id: 1,
                        //     name: '廣告1',
                        //     sent: 555,
                        //     try: 122,
                        //     open: 95,
                        //     click: 67,
                        //     last_run_time: '2021-03-09',
                        //     owner: {
                        //         id: 2,
                        //         full_name: 'Lu ariel',
                        //         first_name: 'ariel',
                        //         last_name: 'Lu',
                        //     }
                        // },
                        // {
                        //     id: 2,
                        //     name: '廣告2',
                        //     sent: 62,
                        //     try: 19,
                        //     open: 45,
                        //     click: 7,
                        //     last_run_time: '2022-01-25',
                        //     owner: {
                        //         id: 2,
                        //         full_name: 'Lu ariel',
                        //         first_name: 'ariel',
                        //         last_name: 'Lu',
                        //     }
                        // },
                    ],
                    sms: [
                        // {
                        //     id: 1,
                        //     name: 'sms廣告1',
                        //     sent: 52,
                        //     try: 39,
                        //     last_run_time: '2021-11-01',
                        //     owner: {
                        //         id: 2,
                        //         full_name: 'Lu ariel',
                        //         first_name: 'ariel',
                        //         last_name: 'Lu',
                        //     }
                        // },
                        // {
                        //     id: 2,
                        //     name: 'sms廣告2',
                        //     sent: 69,
                        //     try: 50,
                        //     last_run_time: '2021-11-19',
                        //     owner: {
                        //         id: 2,
                        //         full_name: 'Lu ariel',
                        //         first_name: 'ariel',
                        //         last_name: 'Lu',
                        //     }
                        // },
                    ],
                },
                isAnalysis: true,
                tabs_without_AI: [
                    {
                        id: 1,
                        key: 'setup',
                        name: i18n.t('CAMPAIGN_DETAIL.SETUP')
                    },
                    {
                        id: 2,
                        key: 'analysis',
                        name: i18n.t('CAMPAIGN_DETAIL.ANALYSIS')
                    },
                ],
                tabs: [
                    {
                        id: 1,
                        key: 'setup',
                        name: i18n.t('CAMPAIGN_DETAIL.SETUP')
                    },
                    {
                        id: 2,
                        key: 'analysis',
                        name: i18n.t('CAMPAIGN_DETAIL.ANALYSIS')
                    },
                    {
                        id: 3,
                        key: 'ai',
                        name: i18n.t('AL_MAIL.TITLE')
                    },
                ],
                breadcrumbs: [{
                        text: i18n.t('BREADCRUMBS.Campaign'),
                        disabled: false,
                        href: '/Campaigns',
                    },
                    {
                        text: "",
                        disabled: true,
                        href: '',
                    },
                ],
                Header_emailCampaign: [
                    {
                        text: i18n.t('CAMPAIGN_DETAIL.CAM_NAME'),
                        value: "name",
                        align: "center",
                        width: '20%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.S_V_S'),
                        value: "sent",
                        align: "center",
                        width: '15%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.O_V_S'),
                        value: "open",
                        align: "center",
                        width: '15%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_V_S'),
                        value: "click",
                        align: "center",
                        width: '15%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('CAMPAIGN_DETAIL.END_TIME_2'),
                        value: "end_time",
                        align: "center",
                        width: '25%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_OWNER'),
                        value: "owner.full_name",
                        align: "center",
                        width: '10%',
                    },
                ],
                Header_SMSCampaign: [
                    {
                        text: i18n.t('CAMPAIGN_DETAIL.CAM_NAME'),
                        value: "name",
                        align: "center",
                        width: '20%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.S_V_S'),
                        value: "try_count",
                        align: "center",
                        width: '45%',
                        sortable: false,
                    },
                    {
                        text: i18n.t('CAMPAIGN_DETAIL.END_TIME_2'),
                        value: "end_time",
                        align: "center",
                        width: '25%',
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_OWNER'),
                        value: "owner.full_name",
                        align: "center",
                        width: '10%',
                    },
                ],
                changeOwnData: {
                    usersetting: {
                        phtoto: ""
                    }
                },
                otherData: {},
                campaignList:[],
                analysisType:"",                
                isDoughnut:true,
                changeSegment:true,
                // Dialog
                selectType:null,
                campaignlineId:"",
                campaignContactList:false,
                changeOwnDialog: false,
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                emailCampaignDialog: false,
                smsCampaignDialog: false,
                emailData: {},
                smsData: {},
                // doughnut chart'
                doughnutData: {
                    labels: ['Lead In', 'Contact Made', 'Needs Defined', 'Proposal Made', 'NegoTiations Started'],
                    datasets: [
                        {
                            data: [20,10,30,20,20],
                            backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)','rgb(0, 170, 96)','rgb(123,104,238)','rgb(240,89,117)'],
                            hoverOffset: 12
                        }
                    ]
                },
                doughnutOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    borderWidth: "10px",
                    hoverBackgroundColor: "red",
                    hoverBorderWidth: "10px",
                    legend: {
                        display: false,
                        position: 'bottom',
                    },
                    plugins: {
                        tooltip: {
                            titleFontSize: 10,
                            bodyFontSize: 10,
                        }
                    },
                    title: {
                        display: true,
                        text: 'Custom Chart Title'
                    },
                    scales:{
                        yAxes:[{
                            ticks:{
                                beginAtZero: true
                            }
                        }]
                    }
                },
                target_id: this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id'),
                contacts:[],
                // table setting     
                awaitingSearch: false,   
                sortBy:[],
                sortByForSearch:[],
                sortByDesc:[],
                pageDataCount:null,
                page_contacts: 1,
                pageCount_contacts: 0,
                itemPerPage_contacts: 10,
                currentData:0,
                selectedRows: [],
                contactHeader: [
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_NAME'),
                        value: "__chineseName__",
                        sortable: false,
                        align: "center"
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_MAIL'),
                        value: "email",
                        sortable: false,
                        align: "center"
                    },
                    {
                        text: i18n.t('DEAL_DETAIL.STATUS'),
                        value: "status",
                        sortable: false,
                        align: "center"
                    },
                ],
                contactHeader_phone: [
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_NAME'),
                        value: "__chineseName__",
                        sortable: false,
                        align: "center"
                    },
                    {
                        text: i18n.t('SEGMENT_DETAIL.C_PHONE'),
                        value: "mobile_phone",
                        sortable: false,
                        align: "center"
                    },
                    {
                        text: i18n.t('DEAL_DETAIL.STATUS'),
                        value: "status",
                        sortable: false,
                        align: "center"
                    },
                ],
                stepContent:false,
                ai_tone:"Professional",
                ai_length:"Medium",
                ai_lang:"Traditional Chinese",
                ai_tone_list:[
                    {name:i18n.t('AL_MAIL.TONE_1'),key:"Professional"},
                    {name:i18n.t('AL_MAIL.TONE_2'),key:"Relax"},
                    {name:i18n.t('AL_MAIL.TONE_3'),key:"Exciting"},
                    {name:i18n.t('AL_MAIL.TONE_4'),key:"Funny"},
                    {name:i18n.t('AL_MAIL.TONE_5'),key:"Passionate"},
                ],
                ai_length_list:[
                    {name:i18n.t('AL_MAIL.LEN_1'),key:"Short"},
                    {name:i18n.t('AL_MAIL.LEN_2'),key:"Medium"},
                    {name:i18n.t('AL_MAIL.LEN_3'),key:"Detailed"},
                ],
                ai_lang_list:[
                    {key:'Traditional Chinese',name:'繁體中文'},
                    {key:'English',name:'English'},
                    {key:'Vietnamese',name:'Tiếng Việt'},
                    {key:'Burmese',name:'မြန်မာအက္ခရာ'},
                    {key:'Laotian',name:'ພະຍັນຊະນະລາວ'},
                    {key:'Turkish',name:'Türkçe'},
                    {key:'Thai',name:'ไทย'},
                    {key:'Telugu',name:'తెలుగు'},
                    {key:'Albanian',name:'Shqip'},
                    {key:'Slovenian',name:'Slovenščina'},
                    {key:'Slovak',name:'Slovenčina'},
                    {key:'Russian',name:'Русский'},
                    {key:'Romanian',name:'Română'},
                    {key:'Brazilian Portuguese',name:'Português do Brasil'},
                    {key:'Portuguese',name:'Português'},
                    {key:'Dutch',name:'Nederlands'},
                    {key:'Mongolian',name:'Mongolian'},
                    {key:'Korean',name:'한국어'},
                    {key:'Georgian',name:'ქართული'},
                    {key:'Japanese',name:'日本語'},
                    {key:'Italian',name:'Italiano'},
                    {key:'Indonesian',name:'Bahasa Indonesia'},
                    {key:'Hungarian',name:'Magyar'},
                    {key:'Hebrew',name:'עברית '},
                    {key:'French',name:'Français'},
                    {key:'Finnish',name:'suomi'},
                    {key:'Spanish',name:'Español'},
                    {key:'German',name:'Deutsch'},
                    {key:'Danish',name:'Dansk'},
                    {key:'Czech',name:'Čeština'},
                    {key:'Catalan',name:'Catalan'},
                    {key:'Bulgarian',name:'Български'},
                    {key:'Arabic',name:'العربية'},
                    {key:'Simplified Chinese',name:'简体中文'},
                    {key:'Polish',name:'Polski'},
                    {key:'Swedish',name:'Svenska'},
                ],
                ai_prompt:"",
                ai_sign:"",
                has_ai_mail:false,
                ai_valid:false,
                ai_subject: '',
                ai_attachments: [],
                ai_emailContext:"",
                ai_smsContext:"",
                ai_message_list : {},
                ai_campaign_setting_id: "",
                current_message_contact:null,
                isAiSending:true,
                isChangeText:false,
                current_page_ai_finish:false,
                haveSetOpenAI:false,
                att_placeholder:'',
                remakeAll:false,
                refreshing:false,
                current_message_contact_index:null,
                ai_total: 0,
                ai_done: 0,
                ai_interval:null,
                common_interval:null,
                ai_loaging:false,
                need_continue:false,
                send_finish:false,
                canSetSmsVendor:false,
                smsVendor:'',
                has_contact:true,
            }
        },
        async created(){
            await (this.uuidCheck(this.target_id))
            await (this.loading = true);
            await CampaignDataService.detail(this.target_id)
            .then(response => {
                this.campaginDetail = response.data;         
                const title = localStorage.getItem('title');    
                document.title = title+' : '+this.campaginDetail.name +' – UpDay';               
                this.channelName = this.replaceChannel(this.campaginDetail.channel);
                this.segmentName = this.campaginDetail.segment.name;
                this.changeOwnData = this.campaginDetail.owner;
                if (this.campaginDetail.owner.usersetting.photo == null) {
                    this.isPhoto = false;
                }
                this.campaignList=this.campaginDetail.campaignline_set;
                this.getAnalysisData();
                this.getChartData();
                this.getBreadCrumb();                
                if(this.campaginDetail.campaignline_set.length == 0){
                    this.changeSegment = false
                }                
            })
            .catch(response => {
                this.showErrorDialog(response);
                // Redirect to PermissionError page if error status is 403
                if (response.response.status == 403)
                {
                    this.$router.replace('/PermissionError');
                }
                else if(response.response.status == 404)
                {
                    this.isSending = false;
                    this.$router.replace('/PageNotFound');
                }
            });
            // get ai_message_list
            await SegmentDataService.contactsList(this.campaginDetail.segment.id)
            .then((response) => {
                this.contacts = response.data.contact_set;
                if(this.contacts.length == 0){
                    this.has_contact = false;
                }
            })
            .catch(()=>{
                this.has_contact = false;
                this.contacts = [];
            })
            if(this.has_contact){
                await this.get_contact_ai_message();
                await this.get_contact_message_data();
            }
            await CampaignDataService.emaildetail(this.target_id)
            .then(response => {
                this.contextDetail = response.data;                      
                this.smsContext = this.contextDetail.smscontext;
                this.subject = this.contextDetail.emailsubject;
                this.emailContext = this.contextDetail.emailcontext;             
            });
            await SegmentDataService.shortList()
            .then(response => {
                this.segmentList = response.data;
            });
            await EmailTemplateDataService.getAll()
            .then(response => {
                this.emailTemplateList = response.data;
            });
            await SMSTemplateDataService.getAll()
            .then(response => {
                this.smsTemplateList = response.data;
            });
            await O365Service.getO365Accounts()
            .then(response => {
                this.o365AccountData = response.data;
                if (this.o365AccountData.length > 0) {
                    this.invalidO365Token = (this.o365AccountData[0].refresh_token_message != '') ? true : false;
                    if (!this.invalidO365Token)
                        this.isO365 = true;
                }
            });
            // PreferenceService.getSmsVendor()
            // .then(response => 
            // {
            //     if(response.data != ""){

            //         this.canSetSmsVendor = true;
            //         this.smsVendor = response.data.vendor;
            //         if(this.smsVendor != "MITAKE"){
            //             this.canSetSmsVendor = false;
            //         }
            //     }
            // })
            // .catch((response) => {
            //         this.showErrorDialog(response);
            // });
            await (this.common_interval = setInterval(() => {this.checkSend();}, 1000));
            await (this.ai_interval = setInterval(() => {this.checkSend_ai("first");}, 1000));
            await (this.loading = false);
            PreferenceService.getOpenAI()
            .then(response => {
                if(response.data != "" && response.data['ai_draft'] == 'Y'){
                    this.haveSetOpenAI = true;
                }
            })
        },
        methods: {
            sendAllAiMessage(){
                this.showMessageDialog(i18n.t("AL_MAIL.SEND_CHECK"));
                this.mode="send_all";
            },
            onAiTextChange(e){
                if(!this.isChangeText){
                    let index = this.contacts.indexOf(this.current_message_contact);
                    this.contacts[index].new_content = e;
                    this.contacts[index].status = "unsaved";
                }
            },
            get_contact_message_data(){
                let ai_message_list_keys = Object.keys(this.ai_message_list);
                this.contacts.forEach((item)=>{
                    if(ai_message_list_keys.find(el=> el == item.id)){
                        let data = this.ai_message_list[item.id];
                        if(data){
                            item.status = data.status;
                            item.original_setting_data = data.original_setting_data;
                            item.original_content = data.original_content;
                            item.new_setting_data = data.new_setting_data;
                            item.new_content = data.new_content;
                        }else{
                            item.status = '';
                            item.original_setting_data = null;
                            item.original_content = null;
                            item.new_setting_data = null;
                            item.new_content = null;
                        }
                        
                    }else{
                        item.status = '';
                        item.original_setting_data = null;
                        item.original_content = null;
                        item.new_setting_data = null;
                        item.new_content = null;
                    }
                })
                let new_contacts = JSON.parse(JSON.stringify(this.contacts))
                setTimeout(()=>{
                    this.contacts = new_contacts;
                },0)
                setTimeout(()=>{
                    if(this.has_ai_mail && !this.refreshing){
                        if(this.current_message_contact_index == null){
                            this.current_message_contact = {status:false}
                        }
                        this.go_next();
                    }else if(this.refreshing && !this.remakeAll){
                        this.current_message_contact = this.contacts[this.current_message_contact_index];
                        this.change_text(this.current_message_contact);
                        this.refreshing = false;
                    }
                },0)
            },
            async get_contact_ai_message(){
                let data = {
                    "campaign_id": this.campaginDetail.id,
                    "contacts_list": this.contacts.slice((this.page_contacts-1)*10 , this.page_contacts*10),
                }
                await UpGPTDataService.get_campaign_message(data)
                .then((response)=>{
                    this.ai_message_list = response.data.txt_content;
                    if(response.data.ai_campaign_setting_DB != null){
                        this.ai_campaign_setting_id = response.data.ai_campaign_setting_DB.id;
                        this.ai_prompt = response.data.ai_campaign_setting_DB.prompt;
                        this.ai_sign = response.data.ai_campaign_setting_DB.signature_line;
                        this.ai_tone = response.data.ai_campaign_setting_DB.tone;
                        this.ai_length = response.data.ai_campaign_setting_DB.content_length;
                        this.ai_lang = response.data.ai_campaign_setting_DB.language;
                        this.ai_subject = response.data.ai_campaign_setting_DB.title;
                        this.current_page_ai_finish = response.data.generate_finished;
                    }else{
                        this.current_page_ai_finish = true;
                        this.has_ai_mail = false;
                        this.isAiSending = false;
                    }
                    if(response.data.ai_campaign_setting_DB != null){
                        switch(response.data.ai_campaign_setting_DB.status){
                            case "正在生成中":
                                this.has_ai_mail = true;
                                this.isAiSending = false;
                                break;
                            case "寄送中":
                                this.has_ai_mail = true;
                                this.isAiSending = true;
                                break;
                        }
                    }else{
                        this.has_ai_mail = false;
                        this.isAiSending = false;
                    }
                    if(response.data.ai_campaign_attachments !=null){
                        this.att_placeholder = "";
                        response.data.ai_campaign_attachments.forEach(element => {
                            let strArray = this.getFileName(element.attachment);
                            this.att_placeholder = this.att_placeholder == "" ? strArray : this.att_placeholder+","+strArray;
                        });
                    }
                    this.need_continue = response.data.need_continue;
                })
            },
            getFileName(filePath) {
                filePath = filePath.split("attachment/")
                return decodeURI(filePath[1].replace(/^.*[\\/]/, '').split('?')[0]);
            },
            async ai_onPreview(){
                if (this.campaginDetail.channel == "EMAIL") {
                        this.emailData = {
                            "campaign_id": this.campaginDetail.id,
                            "campaign_name": this.campaginDetail.name,
                            "subject": this.ai_subject,
                            "attachments": this.ai_attachments,
                            "body": this.ai_emailContext,
                            "contacts": this.campaginDetail.contacts
                        }
                        await (this.emailCampaignDialog = true);
                    } else {
                        this.smsData = {
                            "campaign_id": this.campaginDetail.id,
                            "campaign_name": this.campaginDetail.name,
                            "body": this.ai_smsContext,
                            "contacts": this.campaginDetail.contacts
                        }
                        await (this.smsCampaignDialog = true);
                    }
            },
            save_undo_check(){
                if(this.current_message_contact){
                    if(this.current_message_contact.status == "isRemake"){
                        return false;
                    }
                    if(this.current_message_contact.new_content){
                        return true;
                    }
                    if(this.campaginDetail.channel == "EMAIL"){
                        if(this.current_message_contact.original_content && this.current_message_contact.original_content.replace(/<[^>]+>/ig, '') != this.ai_emailContext.replace(/<[^>]+>/ig, '')){

                            return true;
                        }
                    }
                    if(this.campaginDetail.channel == "SMS"){
                        if(this.current_message_contact.original_content && this.current_message_contact.original_content.replace(/<[^>]+>/ig, '') != this.ai_smsContext.replace(/<[^>]+>/ig, '')){
                            return true;
                        }
                    }
                    return false;
                }else{
                    return false;
                }
            },
            ai_remakeContext_btn(){
                // this.showMessageDialog(i18n.t("AL_MAIL.REMAKE_ALL"));
                // this.mode = "remake";
                this.ai_remakeContext();
            },
            async ai_remakeContext(){
                this.current_page_ai_finish = false;
                let remake_contact = this.current_message_contact;
                let formData = new FormData();
                formData.append('setting_id', this.ai_campaign_setting_id);
                formData.append('type', 'remake');
                formData.append('campaign_id', this.campaginDetail.id);
                formData.append('contacts_list', JSON.stringify([remake_contact]));
                formData.append('channel', this.campaginDetail.channel);
                formData.append('tone', this.ai_tone);
                formData.append('length', this.ai_length);
                formData.append('lang', this.ai_lang);
                formData.append('subject', this.ai_subject);
                formData.append('general_prompt', this.ai_prompt);
                formData.append('sign', this.ai_sign);
                for (let i=0; i<this.ai_attachments.length; i++) {
                    formData.append(`attachments`, this.ai_attachments[i]);
                }
                let index = this.contacts.indexOf(remake_contact);
                this.contacts[index].status = "isRemake";
                this.contacts[index].original_setting_data = null;
                this.contacts[index].original_content = null;
                this.contacts[index].new_setting_data = null;
                this.contacts[index].new_content = null;
                this.current_message_contact = this.contacts[index];
                this.change_text(this.current_message_contact);
                await UpGPTDataService.campaign_message_generate(formData)
                .then((response)=>{
                    let data = response.data;
                    this.contacts[index].status = data.status;
                    this.contacts[index].original_setting_data = data.original_setting_data;
                    this.contacts[index].original_content = data.original_content;
                    this.contacts[index].new_setting_data = data.new_setting_data;
                    this.contacts[index].new_content = data.new_content;
                })
                setTimeout(()=>{
                    this.current_message_contact = this.contacts[index];
                    this.change_text(this.current_message_contact);
                    this.current_page_ai_finish = true;
                },0)
            },
            async ai_undo_or_save_ContextEdit(action){
                let undo_or_save_contact = this.current_message_contact;
                let index = this.contacts.indexOf(undo_or_save_contact);
                let data={
                        action: action,
                        campaign_id: this.campaginDetail.id,
                        contacts_list: undo_or_save_contact,
                    }
                await UpGPTDataService.save_or_undo_campaign_message(data).then((response)=>{
                    let return_data = response.data;
                    this.contacts[index].status = return_data.status;
                    this.contacts[index].original_setting_data = return_data.original_setting_data;
                    this.contacts[index].original_content = return_data.original_content;
                    this.contacts[index].new_setting_data = return_data.new_setting_data;
                    this.contacts[index].new_content = return_data.new_content;
                    this.current_message_contact = this.contacts[index];
                })
                setTimeout(()=>{
                    this.current_message_contact = this.contacts[index];
                    this.change_text(this.current_message_contact);
                },0)
            },
            go_next(val){
                if(this.current_message_contact.status==false){
                    this.current_message_contact = this.contacts[0];
                    this.change_text(this.current_message_contact);
                    return;
                }
                
                let index = this.contacts.findIndex(el => el.id == this.current_message_contact.id);
                if(val=="left"){
                    if(index!=0){
                        this.current_message_contact = this.contacts[index-1];
                        if((index)%10==0){
                            this.page_contacts = this.page_contacts-1;
                        }
                        this.change_text(this.current_message_contact);
                        return;
                    }
                }
                if(val=="right"){
                    if(index!=(this.contacts.length-1)){
                        this.current_message_contact = this.contacts[index+1];
                        if((index)%10==9){
                            this.page_contacts = this.page_contacts+1;
                        }
                        this.change_text(this.current_message_contact);
                        return;
                    }
                }
            },
            get_select_row_class(item){
                if(this.current_message_contact == item)
                    return "select_row_class";
            },
            change_text(item){
                this.isChangeText = true;
                this.ai_emailContext = "";
                this.ai_smsContext = "";
                if(item.original_setting_data!=null){
                    if(this.campaginDetail.channel=="EMAIL"){
                        if(item.new_content){
                        this.ai_emailContext = item.new_content;
                        }else{
                            this.ai_emailContext = item.original_content;
                        }
                    }else{
                        if(item.new_content){
                            this.ai_smsContext = item.new_content;
                        }else{
                            this.ai_smsContext = item.original_content;
                        }
                    }
                }
                setTimeout(()=>{
                    this.isChangeText=false;
                },0)
            },
            select_contact(e){
                this.current_message_contact = e;
                this.change_text(e);
            },
            generate_btn(){
                this.mode="first_generate";
                if(this.has_ai_mail){
                    this.showMessageDialog(i18n.t("AL_MAIL.REMAKE_ALL"));
                    this.remakeAll = true;
                }else{
                    this.ai_message_generate("first_generate");
                    if(this.has_ai_mail != true){
                        if(this.current_message_contact_index == null){
                            this.current_message_contact = {status:false}
                        }
                        this.has_ai_mail = true;
                    }
                }
            },
            async ai_message_generate(type){
                if(type=="first_generate"){
                    this.showErrorDialog(i18n.t("AL_MAIL.MAKE_MSG"));
                }
                this.current_page_ai_finish = false;
                let formData = new FormData();
                formData.append('setting_id', this.ai_campaign_setting_id);
                formData.append('type', type);
                formData.append('channel', this.campaginDetail.channel);
                formData.append('campaign_id', this.campaginDetail.id);
                formData.append('contacts_list', JSON.stringify(this.contacts.slice((this.page_contacts-1)*10 , this.page_contacts*10)));
                formData.append('tone', this.ai_tone);
                formData.append('length', this.ai_length);
                formData.append('lang', this.ai_lang);
                formData.append('subject', this.ai_subject);
                formData.append('general_prompt', this.ai_prompt);
                formData.append('sign', this.ai_sign);
                for (let i=0; i<this.ai_attachments.length; i++) {
                    formData.append(`attachments`, this.ai_attachments[i]);
                }
                // let data={
                //     setting_id: this.ai_campaign_setting_id,
                //     type: type,
                //     campaign_id: this.campaginDetail.id,
                //     contacts_list: this.contacts.slice((this.page_contacts-1)*10 , this.page_contacts*10),
                //     tone: this.ai_tone,
                //     length: this.ai_length,
                //     lang: this.ai_lang,
                //     subject: this.ai_subject,
                //     attachments: this.ai_attachments,
                //     general_prompt: this.ai_prompt,
                //     sign: this.ai_sign,
                // }
                if(type == "send_all")
                {
                    this.isAiSending = true;
                    this.ai_total = this.contacts.length;
                    setTimeout(() => {
                        if(!this.send_finish){
                            this.send_finish = true;
                            this.ai_interval = setInterval(() => {this.checkSend_ai("send_all");}, 1000);
                        }else{
                            this.send_finish = false;
                        }
                    }, 5000);
                }
                await UpGPTDataService.campaign_message_generate(formData).then(()=>{
                    if(type == "send_all"){
                        if(!this.send_finish){
                            this.send_finish = true;
                            this.reset_all();
                            this.showSuccessDialog();
                        }else{
                            this.send_finish = false;
                        }
                    }else{
                        this.refresh_ai();
                        this.has_ai_mail = true;
                    }
                }).catch(()=>{});
            },
            reset_all(){
                this.contacts.forEach((item)=>{
                    item.status = '';
                    item.original_setting_data = null;
                    item.original_content = null;
                    item.new_setting_data = null;
                    item.new_content = null;
                })
                this.ai_prompt = "";
                this.ai_sign = "";
                this.ai_subject = "";
                this.ai_emailContext = "";
                this.ai_smsContext = "";
                this.ai_campaign_setting_id = "";
                this.att_placeholder = "";
                this.ai_attachments = [];
                this.current_page_ai_finish = true;
                this.has_ai_mail = false;
                this.isAiSending = false;
            },
            async refresh_ai(val){
                this.ai_loaging = true;
                this.refreshing = true;
                if(val!="page"){
                    if(this.contacts.indexOf(this.current_message_contact) != -1){
                        this.current_message_contact_index = this.contacts.indexOf(this.current_message_contact);
                    }else{
                        this.current_message_contact_index = 0;
                    }
                }else{
                    this.current_message_contact_index = (this.page_contacts-1)*10;
                }
                await this.get_contact_ai_message();
                await this.get_contact_message_data();
                if(val!="page"){
                    await this.checkSend_ai();
                }
                await (this.ai_loaging = false);
            },
            get_ai_message_status(val){
                switch(val){
                    case "has_message":
                        return i18n.t("AL_MAIL.STATUS_2");
                    case "saved":
                        return i18n.t("AL_MAIL.STATUS_3");
                    case "unsaved":
                        return i18n.t("AL_MAIL.STATUS_4");
                    case "fail":
                        return i18n.t("AL_MAIL.STATUS_5");
                    default :
                        if(this.has_ai_mail){
                            return i18n.t("AL_MAIL.REMAKEING")
                        }else{
                            return i18n.t("AL_MAIL.STATUS_1");
                        }
                }
            },
            onCall: function (cell) {
                return "tel:" + cell;
            },
            ai_field_valid(){
                this.$refs.ai_setting.validate();
            },
            switchStepContent() {
                if (this.stepContent === true) {
                    this.stepContent = false;
                } else {
                    this.stepContent = true;
                }
            },
            onMail: function (mail) {
                return 'mailto:'+mail;
            },
            checkSend() {
                if (this.isSending == true) {
                    CampaignDataService.campaignline(this.target_id,false)
                    .then(response => {
                        this.campaignlineList = response.data.campaignline_set
                        if (this.campaignlineList.length == 0) {
                            this.isSending = false;
                        }
                        let count = 0;
                        for(var index in this.campaignlineList) {
                            if(!this.campaignlineList[index].is_ai){
                                let total = this.campaignlineList[index].try_count;
                                let done = 0;
                                if (this.campaignlineList[index].channel == "EMAIL") {
                                    done = this.campaignlineList[index].linkedemail_set.length;
                                } else {
                                    done = this.campaignlineList[index].linkedsms_set.length;
                                }
                                if (total != done) {
                                    this.total = total;
                                    this.done = done;
                                    this.isSending = true;
                                    break;
                                } else {
                                    this.isSending = false;
                                }
                            }else{
                                count++;
                            }
                        }
                        if(count == this.campaignlineList.length){
                            this.isSending = false;
                        }
                    });
                } else {
                    return;
                }
            },
            checkSend_ai(val){
                if (this.isAiSending == true || val == "first") {
                    CampaignDataService.campaignline(this.target_id,true)
                    .then(response => {
                        this.campaignlineList = response.data.campaignline_set
                        if (this.campaignlineList.length == 0) {
                            this.isAiSending = false;
                            clearInterval(this.ai_interval);
                        }
                        for(var index in this.campaignlineList) {
                            if(this.campaignlineList[index].is_ai){
                                let ai_total = this.campaignlineList[index].try_count;
                                let ai_done = 0;
                                if (this.campaignlineList[index].channel == "EMAIL") {
                                    ai_done = this.campaignlineList[index].linkedemail_set.length;
                                } else {
                                    ai_done = this.campaignlineList[index].linkedsms_set.length;
                                }
                                if (ai_total != ai_done) {
                                    this.ai_total = ai_total;
                                    this.ai_done = ai_done;
                                    this.isAiSending = true;
                                    break;
                                }else {
                                    this.isAiSending = false;
                                    clearInterval(this.ai_interval);
                                    if(val=="first"){
                                        if(!this.has_ai_mail){
                                            this.reset_all();
                                        }
                                    }
                                    if(val=="send_all"){
                                        this.reset_all();
                                        this.showSuccessDialog();
                                    }
                                    break;
                                }
                            }
                        }
                    });
                } else {
                    return;
                }
            },
            validateForm(){
                if(this.$refs.formCampaign.validate() ){
                    if(this.campaginDetail.channel == "SMS")
                    {
                        return true;
                    }
                    else if(this.campaginDetail.channel == "EMAIL" && !this.isO365)
                    {
                        return true;
                    }
                    else if(this.campaginDetail.channel == "EMAIL" && this.$refs.formEmailFile.validate())
                    {
                        return true;
                    }else
                    {
                        return false;
                    }
                }
                else{
                    return false;
                }
            },            
            getFormatTime(data){
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
                let today  = data;
                if(this.$i18n.locale == "zh_TW")
                {
                   return today.toLocaleDateString("zh-TW", options); 
                }else
                {
                    return today.toLocaleDateString("en-US", options);
                }                
            },
            getTabName(key){
                if(key == 'setup')
                    this.isSetupTab = true;
                else
                    this.isSetupTab = false;
                if(key == 'contact')
                    this.isNoteTab = true;
                else
                    this.isNoteTab = false;

                if(key == 'analysis')
                    {
                        this.isAnalysis = true;
                        this.getChartData()
                    }
                else
                    this.isAnalysis = false;
            },
            getBreadCrumb(){
                let campaign = this.campaginDetail;
                this.breadcrumbs[1].text = campaign.name;
            },
            // Dialog
            onChangeOwn() {
                this.otherData.campaign_id = this.campaginDetail.id;
                this.otherData.name = this.campaginDetail.name;
                this.otherData.channel = this.campaginDetail.channel;
                this.otherData.segment = this.campaginDetail.segment;
                this.changeOwnDialog = true;
            },
            onEmitChangeOwnDialog(val) {
                this.changeOwnDialog = false;
                if (val) {
                    this.showSuccessDialog();
                }
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                if (val) {
                    if(this.remakeAll){
                        this.contacts.forEach((item)=>{
                            item.status = '';
                            item.original_setting_data = null;
                            item.original_content = null;
                            item.new_setting_data = null;
                            item.new_content = null;
                        })
                        this.ai_message_generate("first_generate");
                    }
                    // if(this.mode == "remake")
                    // {
                    //     this.ai_remakeContext();
                    //     this.mode == "";
                    // }
                    if(this.mode == "send_all")
                    {
                        this.ai_message_generate("send_all");
                    }
                    if(this.mode == "DELETE") {
                        CampaignDataService.delete(this.target_id)
                        .then(() => {
                            this.showSuccessDialog();
                            setTimeout(() => {this.$router.replace('/Campaigns');}, 1000)
                        });
                    }
                }
                this.mode == "";
                this.remakeAll = false;
                this.messageDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            onDelete () {
                this.mode = "DELETE"
                this.showMessageDialog(i18n.t('CAMPAIGN_DETAIL.MESSAGE'));
            },
            async onPreview() {
                if (!this.isReadonly) {
                    await this.confirmEditDetail();
                }
                if (this.validateForm()) {
                    if (this.campaginDetail.channel == "EMAIL") {
                        if (!this.subject) {
                            this.showErrorDialog(i18n.t('CAMPAIGN_DETAIL.ERROR'));
                            return;
                        }
                        if (!this.emailContext) {
                            this.showErrorDialog(i18n.t('CAMPAIGN_DETAIL.ERROR_2'));
                            return;
                        }
                        if (this.campaginDetail.contacts.length == 0) {
                            this.showErrorDialog(i18n.t('CAMPAIGN_DETAIL.ERROR_3'));
                            return;
                        }
                        this.emailData = {
                            "campaign_id": this.campaginDetail.id,
                            "campaign_name": this.campaginDetail.name,
                            "subject": this.subject,
                            "attachments": this.attachments,
                            "body": this.emailContext,
                            "contacts": this.campaginDetail.contacts
                        }
                        await (this.emailCampaignDialog = true);
                    } else {
                        if (this.smsContext == "") {
                            this.showErrorDialog(i18n.t('CAMPAIGN_DETAIL.ERROR_2'));
                            return;
                        }
                        this.smsData = {
                            "campaign_id": this.campaginDetail.id,
                            "campaign_name": this.campaginDetail.name,
                            "body": this.smsContext,
                            "contacts": this.campaginDetail.contacts
                        }
                        await (this.smsCampaignDialog = true);
                    }
                }
            },
            onEmitEmailCampaignDialog(val) {
                this.emailCampaignDialog = false;
                if (val) {
                    this.showSuccessDialog();
                    this.isSending = true;
                    this.changeSegment = true
                }
            },
            onEmitSMSCampaignDialog(val) {
                this.smsCampaignDialog = false;
                if (val) {
                    this.showSuccessDialog();
                    this.isSending = true;
                    this.changeSegment = true
                }
            },
            replaceChannel(key) {
                let index = this.channelList.findIndex(item => {return item.key == key});
                return this.channelList[index].name;
            },
            onEditDetail() {
                this.isEdit = true;
                this.isReadonly = false;
                this.originData = JSON.parse(JSON.stringify(this.campaginDetail));
            },
            cancelEditDetail() {
                this.isEdit = false;
                this.isReadonly = true;
                this.campaginDetail = this.originData;
                this.channelName = this.replaceChannel(this.campaginDetail.channel);
            },
            async confirmEditDetail() {
                if(this.validateForm()) {
                    await (this.loading_campaginDeatil = true);
                    let campaignData = {};
                    if (typeof(this.campaginDetail.segment) == "object") {
                        campaignData = {
                            "id": this.campaginDetail.id,
                            "name": this.campaginDetail.name,
                            "channel": this.campaginDetail.channel,
                            "segment": this.campaginDetail.segment,
                            "description": this.campaginDetail.description,
                            "type":this.campaginDetail.type,
                            "start_time":this.campaginDetail.start_time,
                            "end_time":this.campaginDetail.end_time,
                            "status":this.campaginDetail.status,
                        };
                    } else {
                        campaignData = {
                            "id": this.campaginDetail.id,
                            "name": this.campaginDetail.name,
                            "channel": this.campaginDetail.channel,
                            "segment": {
                                "id": this.campaginDetail.segment,
                            },
                            "description": this.campaginDetail.description,
                            "type":this.campaginDetail.type,
                            "start_time":this.campaginDetail.start_time,
                            "end_time":this.campaginDetail.end_time,
                            "status":this.campaginDetail.status,
                        };
                    }
                    await CampaignDataService.edit(campaignData)
                    .then(response => {
                        if(this.campaginDetail.segment!=response.data.segment || this.campaginDetail.channel!=response.data.channel){
                            this.reset_all();
                        }
                        this.campaginDetail = response.data;
                        this.channelName = this.replaceChannel(this.campaginDetail.channel);
                        this.segmentName = this.campaginDetail.segment.name;
                    });
                    this.isEdit = false;
                    this.isReadonly = true;
                    await (this.loading_campaginDeatil = false);
                }
            },
            async saveContextEdit() {
                if(this.validateForm()) {
                    let contextData = {};
                    if (this.campaginDetail.channel == "EMAIL"){
                        contextData = {
                            "id": this.campaginDetail.id,
                            "channel": this.campaginDetail.channel,
                            "emailsubject": this.subject,
                            "emailcontext": this.emailContext,
                        };
                    }else{
                        contextData = {
                            "id": this.campaginDetail.id,
                            "channel": this.campaginDetail.channel,
                            "smscontext": this.smsContext,
                        }
                    }
                    await CampaignDataService.emailedit(contextData)
                    .then(response => {
                        this.contextDetail = response.data;
                        if (this.campaginDetail.channel == "EMAIL"){
                            this.subject = this.contextDetail.emailsubject;
                            this.emailContext = this.contextDetail.emailcontext;
                        }else{
                            this.smsContext = this.contextDetail.smscontext;
                        }
                    });
                }
            },
            onDescEditDetail() {
                this.isDescReadonly = false;
                this.originData = JSON.parse(JSON.stringify(this.campaginDetail));
            },
            cancelDescEditDetail() {
                this.isDescReadonly = true;
                this.campaginDetail = this.originData;
            },
            confirmDescEditDetail() {
                if(this.validateForm()) {
                    let campaignData = {};
                    campaignData = {
                        "id": this.campaginDetail.id,
                        "name": this.campaginDetail.name,
                        "channel": this.campaginDetail.channel,
                        "segment": this.campaginDetail.segment,
                        "description": this.campaginDetail.description,
                        "status":this.campaginDetail.status,
                    };
                    CampaignDataService.edit(campaignData)
                    .then(response => {
                        this.campaginDetail = response.data;
                    });
                    this.isDescReadonly = true;
                }
            },
            changeTemplate() {
                if (this.campaginDetail.channel == "EMAIL") {
                    let index = this.emailTemplateList.findIndex(item => {return item.id == this.emailTemplate});
                    this.emailContext = this.emailTemplateList[index].template;
                    this.subject = this.emailTemplateList[index].subject;
                } else {
                    let index = this.smsTemplateList.findIndex(item => {return item.id == this.smsTemplate});
                    this.smsContext = this.smsTemplateList[index].template;
                }
            },
            changeChannel() {
                this.channelName = this.replaceChannel(this.campaginDetail.channel);
            },
            getAnalysisData(){                
                let isSMS = this.campaginDetail.campaignline_set.find(e=>e.channel === "SMS")
                let isEMAIL = this.campaginDetail.campaignline_set.find(e=>e.channel === "EMAIL")
                if(isSMS && isEMAIL)
                {
                    this.analysisType = "BOTH"
                }
                else
                {
                    if(this.campaginDetail.campaignline_set.length >0)
                    {
                        this.analysisType = this.campaginDetail.campaignline_set[0].channel
                    }
                    else
                    {
                        this.analysisType = this.campaginDetail.channel
                    }
                } 
                
                if(this.analysisType === "EMAIL" || this.analysisType === "BOTH")
                {
                    this.analysis.email = []
                    let emailData = this.campaginDetail.campaignline_set.filter(e=>e.channel === "EMAIL")
                                               
                        if(emailData.length>0)
                        {                                   
                            for(let a in emailData)
                            {                                    
                                this.analysis.email.push(emailData[a])                                   
                            }                                
                        }                           
                        
                    for(let i in this.analysis.email)
                    {
                        if(!this.analysis.email[i].name)
                        {
                            this.analysis.email[i].name = this.analysis.email[i].campaign                            
                            this.analysis.email[i].owner = this.campaginDetail.owner
                        }                                               
                        this.analysis.email[i].owner.full_name = this.analysis.email[i].owner.last_name +" "+ this.analysis.email[i].owner.first_name
                        if(this.analysis.email[i].end_time)
                        {
                            this.analysis.email[i].end_time = this.getFormatTime(new Date(this.analysis.email[i].end_time))
                        }
                    }}  

                    if(this.analysisType === "SMS" || this.analysisType === "BOTH")
                    {this.analysis.sms = []
                    let smsData = this.campaginDetail.campaignline_set.filter(e=>e.channel === "SMS")                  
                        if(smsData.length>0)
                        {   
                            for(let a in smsData)
                            {                                    
                                this.analysis.sms.push(smsData[a])                                   
                            } 
                        }
                    for(let i in this.analysis.sms)
                    {
                        if(!this.analysis.sms[i].name)
                        {
                            this.analysis.sms[i].name = this.analysis.sms[i].campaign
                            this.analysis.sms[i].owner = this.campaginDetail.owner
                        }                        
                        this.analysis.sms[i].owner.full_name = this.analysis.sms[i].owner.last_name +" "+ this.analysis.sms[i].owner.first_name
                        if(this.analysis.sms[i].end_time)
                        {
                            this.analysis.sms[i].end_time = this.getFormatTime(new Date(this.analysis.sms[i].end_time))
                        }                        
                    }}
            },
            updateChart(){
                this.isDoughnut = false
            },
            async getChartData(){                               
                await this.updateChart()           
                if(this.analysisType === "EMAIL" || this.analysisType === "BOTH")
                {                                  
                    let doughnutEmail = this.analysis.email.filter(e=>e.campaign_id===this.campaginDetail.id)
                    if(doughnutEmail.length>0)
                    {
                        this.doughnutOptionsAll = JSON.parse(JSON.stringify(this.doughnutOptions))
                        this.doughnutOptionsOpen = JSON.parse(JSON.stringify(this.doughnutOptions))
                        this.doughnutOptionsClick = JSON.parse(JSON.stringify(this.doughnutOptions))
                        this.doughnutOptionsAll.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_S')
                        this.doughnutOptionsOpen.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_O')
                        this.doughnutOptionsClick.title.text = 'Email '+i18n.t('SEGMENT_DETAIL.S_V_C')
                        this.isDoughnut = true
                        let doughnutAll = {error:0,total:0}                     
                        let doughnutOpen = 0
                        let doughnutClick = 0
                        for (let i in doughnutEmail)
                        {
                            doughnutAll.error = doughnutAll.error+doughnutEmail[i].error_count
                            doughnutAll.total = doughnutAll.total+doughnutEmail[i].try_count
                            doughnutOpen = doughnutOpen + doughnutEmail[i].open_and_link.open
                            doughnutClick = doughnutClick + doughnutEmail[i].open_and_link.link 
                        }
                        let doughnutS = doughnutAll.total - doughnutAll.error                    
                        this.doughnutDataAll={
                            labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.SENT')],
                            datasets: [
                                {
                                    data: [doughnutS,doughnutAll.total],
                                    backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                    hoverOffset: 12
                                }
                            ]
                            }           
                        this.doughnutDataOpen={
                            labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.OPEN')],
                            datasets: [
                                {
                                    data: [doughnutS,doughnutOpen],
                                    backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                    hoverOffset: 12
                                }
                            ]
                            }
                        this.doughnutDataClick={
                            labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.CLICK')],
                            datasets: [
                                {
                                    data: [doughnutS,doughnutClick],
                                    backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                    hoverOffset: 12
                                }
                            ]
                        }                                   
                    }
                    else{
                        this.isDoughnut = false
                    }
                }
                if(this.analysisType === "SMS" || this.analysisType === "BOTH")
                {                  
                    let doughnutSMS = this.analysis.sms.filter(e=>e.campaign_id===this.campaginDetail.id)
                    if(doughnutSMS.length>0)
                    {
                        this.doughnutOptionsSMS = JSON.parse(JSON.stringify(this.doughnutOptions))
                        this.doughnutOptionsSMS.title.text = 'SMS '+i18n.t('SEGMENT_DETAIL.S_V_S')
                        this.isDoughnut = true
                        let doughnutAll = {error:0,total:0}
                        for (let i in doughnutSMS)
                        {
                            doughnutAll.error = doughnutAll.error+doughnutSMS[i].error_count
                            doughnutAll.total = doughnutAll.total+doughnutSMS[i].try_count                        
                        }
                        let doughnutS = doughnutAll.total - doughnutAll.error
                        this.doughnutDataSMS={
                            labels:[i18n.t('SEGMENT_DETAIL.SUCCESS'),i18n.t('SEGMENT_DETAIL.SENT')],
                            datasets: [
                                {
                                    data: [doughnutS,doughnutAll.total],
                                    backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)'],
                                    hoverOffset: 12
                                }
                            ]
                        }          
                    }
                    else{
                        this.isDoughnut = false
                    }
                }
            }, 
            addEmailTemplateFields(val) {
                let injectedStr = `{{${val}}}`;
                const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi;
                let html = this.emailContext;
                if (html == "") {
                    this.emailContext = injectedStr;
                    this.templateIndex = injectedStr.length;
                    return true;
                }
                let htmlArr = html.match(pattern);
                let htmlTextSizeArr = htmlArr.map(t => t.replace(/<[^>]+>/g,"").length+1);	// get text length of within each html tag
                let position = this.templateIndex;						// position of cursor.  If 0, add to the end
                if (position == 0) {
                    let text = htmlArr[0];
                    let lastPos = text.indexOf(">") + 1;
                    let newText = [text.slice(0, lastPos), injectedStr, text.slice(lastPos)].join('');
                    htmlArr[0] = newText;
                    this.templateIndex = injectedStr.length;
                } else {
                    let i = 0;
                    while(position >= 0) {
                        position -= htmlTextSizeArr[i++];
                    }
                    let targetInArr = i-1;
                    if (htmlTextSizeArr[targetInArr] == 1) {	// only <br> in the line
                        htmlArr[targetInArr] = htmlArr[targetInArr].replace('<br>', injectedStr);
                    } else {
                        let htmlText = htmlArr[targetInArr];
                        let positionInArr = position + htmlTextSizeArr[targetInArr];
                        let j = 0;
                        let startCounting = true;
                        while(positionInArr > 0) {
                            let currentChar = htmlText.charAt(j);
                            switch(currentChar) {
                                case "<":
                                    startCounting = false;
                                    break;
                                case ">":
                                    startCounting = true;
                                    break;
                                default:
                                    if (startCounting)
                                        positionInArr--;
                                    break;
                            }
                            j++;
                        }
                        let newHtmlText = [htmlText.slice(0, j), injectedStr, htmlText.slice(j)].join('');
                        htmlArr[targetInArr] = newHtmlText;
                    }
                    this.templateIndex = this.templateIndex + injectedStr.length;
                }
                this.emailContext = htmlArr.join('');
            },
            addSMSTemplateFields(val) {
                const injectedStr = `{{${val}}}`;

                if (!injectedStr.length) return

                const textarea = this.$refs.textarea.$refs.input
                const sentence = textarea.value
                const len = sentence.length
                let pos = textarea.selectionStart
                if (pos === undefined) {
                    pos = 0
                }

                const before = sentence.substr(0, pos)
                const after = sentence.substr(pos, len)

                const value = before + injectedStr + after;

                this.smsContext = value;
            },
            onSelectionChange(range) {
                if (range != null) {
                    this.templateIndex = range.index;
                }
            },
            showContactList(data,type){   
                this.campaignlineId = data
                this.selectType = type                
                this.campaignContactList = true                
            },            
            onEmitAddSegContactDialog(val){               
                if(val){        
                    this.campaignContactList = false;
                }
                else{
                    this.campaignContactList = false;
                }
            }, 
            onTextChange(delta) {
                // this.templateIndex = delta.ops[0].retain + 1;
                if (Object.keys(delta.ops[0])[0] == 'retain') {
                    if (Object.keys(delta.ops[1])[0] == 'insert') {
                        if (delta.ops[1].insert.length <= 2) {
                            this.templateIndex = delta.ops[0].retain + 1;
                        }
                    } else if (Object.keys(delta.ops[1])[0] == 'delete') {
                        this.templateIndex = delta.ops[0].retain;
                    }
                }
            },
            onChangeFile(event) {
                let sum = 0;
                event.forEach(file => {
                    sum = sum + file.size;
                });

                if (sum > 20971520) {
                    this.isFileAvailable = false;
                } else {
                    this.isFileAvailable = true;
                }
            },
        },
        watch: {
            isReadonly(val){
                this.removeMenu(val);
            },
            "campaginDetail.channel": function(){
                if(this.has_ai_mail && this.campaginDetail.channel != this.originData.channel && !this.loading_campaginDeatil)
                {
                    this.showErrorDialog(i18n.t("AL_MAIL.CHANGE_ERROR"));
                }
            },
            "campaginDetail.segment": function(){
                if(this.has_ai_mail && this.campaginDetail.segment != this.originData.segment && !this.loading_campaginDeatil)
                {
                    this.showErrorDialog(i18n.t("AL_MAIL.CHANGE_ERROR"));
                }
            },
            "tab" : function(){
                if(this.tab == "tab-3"){
                    this.isChangeText = true;
                    setTimeout(()=>{
                        this.isChangeText=false;
                    },100)
                    if(this.need_continue && this.ai_campaign_setting_id != "" && !this.isAiSending){
                        this.need_continue = false
                        this.ai_message_generate("continue_generate");
                    }
                }
            },
             "page_contacts" :async function(){
                if(this.has_ai_mail){
                    await this.refresh_ai("page");
                    if(this.need_continue && this.ai_campaign_setting_id != "" && !this.isAiSending){
                        this.need_continue = false;
                        await this.ai_message_generate("continue_generate");
                    }
                }
            },
        }
    }
</script>

<style lang="css">
    .template {
        border: 2px solid gray;
        border-radius: 5px;
        /* box-shadow: 5px 5px grey; */
        padding: 10px 15px;
    }
    .text-with-overline{text-decoration: underline;}

    .campaignRowExtraCss .v-input__control .v-text-field__details{
        /* avoid lookupfield hard to choose */
        z-index: 0 !important;
    }
    
    .blankaimail{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .blankaimail img{
        height: 500px;
    }

    .blankaimail .flyver{
        height: 100px;
    }
    
    .blankaimail div{
        color: rgba(0, 0, 0, 0.54);
        font-size: 1.25rem;
        font-weight: 700;
    }
    .smsTextArea textarea::-webkit-scrollbar {
            height: 8px !important;
            width: 8px !important;
        }
    .smsTextArea textarea::-webkit-scrollbar-thumb {
            border-radius: 8px !important;
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2) !important;
            background-color: rgba(0,0,0,0.2) !important;
        }
    .smsTextArea textarea::-webkit-scrollbar-track {
            border-radius:0px !important;
        }
</style>